import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCheck, faConstruction, faExclamationCircle, faExclamationTriangle, faMoneyBill, faSync } from '@fortawesome/pro-light-svg-icons';

import * as GetProductBP from '../../../blueprints/products/get-product';
import * as SetProductStatusBP from '../../../blueprints/products/set-product-status';
import * as PerformBillingBP from '../../../blueprints/subscriptions/manual-perform-billing';
import { useParams } from 'react-router-dom';
import { seamlessAuth, seamlessClient } from '../../../seamless';
import { GrowLoading } from '../../ux/loading-spinner';
import { EmptySplash } from '@hiyllo/ux/empty-splash';
import { ProductKind, ProductStatusEnum } from '../../../types/product';
import { Select } from '@hiyllo/ux/select';
import { Typography } from '@hiyllo/ux/typography';
import { InlineLink } from '@hiyllo/ux/inline-link';
import { Card } from '@hiyllo/ux/surface';
import { ProductBadge } from './badge';
import moment from 'moment';
import { PlatformStatusEnum } from '../../../types/platforms';
import { Row } from '../../ux/row';
import { CircleButton } from '@hiyllo/ux/circle-button';
import { useShowConfirm } from '@hiyllo/ux/dialogs';
import { Button } from '@hiyllo/ux/button';
import { Table, TableRow } from '../table';

const STATUS_SELECT_OPTIONS = [
    {
        label: 'Active',
        value: PlatformStatusEnum.active
    },
    {
        label: 'Restricted',
        value: PlatformStatusEnum.restricted
    },
    {
        label: 'Terminated',
        value: PlatformStatusEnum.terminated
    }
];

export const ProductDetails = React.memo(function ProductDetails(): JSX.Element {
    const { uuid } = useParams<{uuid: string}>();
    const authState = seamlessAuth.useAuthState();
    const productQuery = seamlessClient.useQuery<GetProductBP.Plug>(GetProductBP, { productUUID: uuid ?? '' });
    const setProductStatusMutation = seamlessClient.useMutation<SetProductStatusBP.Plug>(SetProductStatusBP, { querySideEffects: [productQuery] });
    const performBillingMutation = seamlessClient.useMutation<PerformBillingBP.Plug>(PerformBillingBP, { querySideEffects: [productQuery] });
    const [status, setStatus] = React.useState<ProductStatusEnum | null>(null);
    const showConfirm = useShowConfirm();

    const performBilling = React.useCallback(async () => {
        if (productQuery.data?.subscription.uuid == null) {
            return;
        }

        const subscriptionUUID = productQuery.data?.subscription.uuid;

        void showConfirm({
            title: 'Perform Billing',
            message: 'Are you sure you want to perform billing for this product?'
        }).then(async (confirmed) => {
            if (confirmed) {
                void performBillingMutation.call({ subscriptionUUID });
            }
        });
    }, [performBillingMutation, productQuery.data?.subscription, showConfirm]);

    if (productQuery.isLoading) {
        return <GrowLoading/>;
    }

    if (productQuery.isError) {
        return (
            <EmptySplash
                icon={faExclamationTriangle}
                label='Error loading product'
                hint={productQuery.error.message}
            />
        );
    }

    const { product, subscription, federation, recentInvoices } = productQuery.data;

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 30 }}>
            <div style={{ width: 400, flexShrink: 0, flexGrow: 1 }}>
                <ProductBadge product={product}/>

                {product.status === ProductStatusEnum.restricted ?
                    <>
                        <div style={{ height: 15 }}/>
                        <Card color="background3">
                            <Typography.SubHeader>
                                <Typography.HeaderRow>
                                    <FontAwesomeIcon icon={faExclamationCircle}/>
                                    <div>Restricted</div>
                                </Typography.HeaderRow>
                            </Typography.SubHeader>
                            <Typography.Paragraph>
                                {product.name} workspace is currently restricted. Check the billing tab
                            </Typography.Paragraph>
                        </Card>
                    </>
                : null}

                {product.status === ProductStatusEnum.suspended ?
                    <>
                        <div style={{ height: 15 }}/>
                        <Card color="background3">
                            <Typography.SubHeader>
                                <Typography.HeaderRow>
                                    <FontAwesomeIcon icon={faExclamationCircle}/>
                                    <div>Suspended</div>
                                </Typography.HeaderRow>
                            </Typography.SubHeader>
                            <Typography.Paragraph>
                                {product.name} workspace is currently suspended. Check the billing tab
                            </Typography.Paragraph>
                        </Card>
                    </>
                : null}

                <div style={{ height: 15 }}/>

                <div>
                    <Typography.Header>{product.name}</Typography.Header>
                    <div style={{ height: 2.5 }}/>
                    <InlineLink href={`https://${product.slug}.hiyllo.${product.kind.type}`} label={`${product.slug}.hiyllo.${product.kind.type}`} />
                </div>

                {authState?.type === 'hiyllo-employee' ?
                    <div>
                        <Row>
                            <Select
                                options={STATUS_SELECT_OPTIONS}
                                value={status}
                                // @ts-expect-error ---
                                onChangeValue={setStatus}
                            />
                            {status != null ?
                                <CircleButton
                                    size={25}
                                    icon={faCheck}
                                    onClick={async () => {
                                        await setProductStatusMutation.call({ productUUID: product.uuid, status });
                                        setStatus(null);
                                    }}
                                />
                            : null}
                        </Row>
                        <div style={{ height: 10 }}/>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faBuilding} fixedWidth/>
                            <div><b>Federation: </b>{federation.name} ({product.slug})</div>
                        </div>
                        <div style={{ height: 5 }}/>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faSync} fixedWidth/>
                            <div>
                                <b>Last Synced: </b> {moment(product.usage?.syncedAt ?? 0).format('h:mm a, MMM Do, YYYY')}
                            </div>
                        </div>
                        <div style={{ height: 5 }}/>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faMoneyBill} fixedWidth/>
                            <div>
                                <b>Subscription UUID: </b> {subscription.uuid}
                            </div>
                        </div>
                        <div style={{ height: 5 }}/>
                        <div>
                            <Button
                                label="Perform Billing"
                                onClick={performBilling}
                                isLoading={performBillingMutation.isLoading}
                                autoWidth
                            />
                        </div>
                    </div>
                : null}
            </div>
            <div style={{ flexGrow: 1 }}>
                <Card color="background2">
                    <div style={{ fontWeight: 'bold', fontSize: 32, marginBottom: 10 }}>
                        Your Plan
                    </div>
                    <div>
                        {subscription.freeTrial != null ?
                            <div style={{ marginBottom: 10 }}>Your free trial will end <b>{moment(subscription.freeTrial.expires).format('MMM Do, YYYY')}</b></div>
                        : null}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 30 }}>
                        <div>
                            <div>Price per User per Month</div>
                            <div style={{ fontSize: 24, fontWeight: 'bold', marginTop: 2.5 }}>
                                ${(subscription.billingRate.costPerUser / 100).toFixed(2)}
                            </div>
                        </div>
                        <div>
                            <div>Current Billable Users</div>
                            <div style={{ fontSize: 24, fontWeight: 'bold', marginTop: 2.5 }}>
                                {product.usage?.billableUsersCurrent ?? 1}
                            </div>
                        </div>
                        {product.usage?.billableUsersPeak !== product.usage?.billableUsersCurrent ?
                            <div>
                                <div>Peak Billable Users this Month</div>
                                <div style={{ fontSize: 24, fontWeight: 'bold', marginTop: 2.5 }}>
                                    {product.usage?.billableUsersPeak ?? 1}
                                </div>
                            </div>
                        : null}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 30 }}>
                        <div>
                            <div>Included storage (25GB per user)</div>
                            <div style={{ fontSize: 24, fontWeight: 'bold', marginTop: 2.5 }}>
                                {(product.usage?.billableUsersCurrent ?? 1) * 25} GB
                            </div>
                        </div>
                        <div>
                            <div>Used Storage</div>
                            <div style={{ fontSize: 24, fontWeight: 'bold', marginTop: 2.5 }}>
                                {product.usage?.storageUsageCurrent ?? 0} GB
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 30 }}>
                        <div>
                            <div>Renewal Amount</div>
                            <div style={{ fontSize: 24, fontWeight: 'bold', marginTop: 2.5 }}>
                                ${((subscription.billingRate.costPerUser * (product.usage?.billableUsersCurrent ?? 1)) / 100).toFixed(2)}
                            </div>
                        </div>
                    </div>
                    </div>
                </Card>
                {authState?.type === 'hiyllo-employee' ?
                    <div>
                        <Table>
                        {recentInvoices.map(invoice => (
                            <TableRow key={invoice.uuid}>
                                <div>
                                    [{invoice.status}]
                                </div>
                                <div>
                                    Invoice #{invoice.uuid}
                                </div>
                                <div>
                                    {moment(invoice.date).format('MMM Do, YYYY')}
                                </div>
                                <div>
                                    {invoice.totalCost}
                                </div>
                            </TableRow>
                        ))}
                        </Table>
                    </div>
                : null}
            </div>
        </div>
    );
});
