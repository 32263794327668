import React from 'react';
import { styled } from '@hiyllo/ux/styled';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { HiylloIcon, isHiylloIcon } from '@hiyllo/icons/main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@hiyllo/ux/typography';

const Card = styled('div', ({ $theme }) => ({
    border: `1px solid ${$theme.background3 ?? ''}`,
    borderRadius: 5,
    padding: 20,
    flex: 3,
    minWidth: '26vw',
    flexShrink: 1,
    flexGrow: 1
}));

const ComingSoonBadge = styled('div', ({ $theme }) => ({
    background: $theme.buttonBackground,
    color: $theme.foreground,
    borderRadius: 5,
    padding: '5px 10px',
    marginBottom: 10,
    display: 'inline-block'
}));

export const FeatureInfoCard = React.memo(function FeatureInfoCard(props: {
    icon: IconDefinition | HiylloIcon;
    title: string;
    description: string;
    comingSoon?: boolean;
}): JSX.Element {
    return (
        <Card>
            {isHiylloIcon(props.icon) ?
                <props.icon width={50} color='white'/>
            :
                <FontAwesomeIcon icon={props.icon} style={{ fontSize: 50 }} />
            }
            <Typography.Header style={{ fontSize: 27.5, marginTop: 10 }}>{props.title}</Typography.Header>
            <div style={{ height: 5 }}/>
            <div>{props.description}{!props.description.endsWith('.') ? '.' : ''}</div>
            {props.comingSoon === true ?
                <div>
                    <div style={{ height: 10 }}/>
                    <ComingSoonBadge>Coming Soon</ComingSoonBadge>
                </div>
            : null}
        </Card>
    );
});

const Pill = styled('div', ({ $theme }) => ({
    background: $theme.midground1,
    color: $theme.foreground,
    borderRadius: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5
}));

export const FeaturePill = React.memo(function FeaturePill(props: {
    icon: IconDefinition | HiylloIcon;
    label: string;
}): JSX.Element {
    return (
        <Pill>
            {isHiylloIcon(props.icon) ?
                <props.icon width={15} color='white'/>
            :
                <FontAwesomeIcon icon={props.icon} style={{ fontSize: 15 }} />
            }
            <div>{props.label}</div>
        </Pill>
    );
});
