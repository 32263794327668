import React from 'react';
import { InfoHeader } from '../header';
import { styled } from '@hiyllo/ux/styled';
import { Typography } from '@hiyllo/ux/typography';
import { GradientText } from '../../landing';
import { faAt, faComment, faCrateEmpty, faDiagramNested, faFont, faHashtag, faLink, faListCheck, faPaperclip, faReply, faRunning, faSearch, faSmileWink, faSortSizeDown, faSquareKanban, faThumbTack, faTransporter2, faUpDown, faUser, faUserPlus, faUsers } from '@fortawesome/pro-light-svg-icons';
import { ChatThreadIcon, NymblIcon, VideoMeetingIcon } from '@hiyllo/icons/main';
import { FeatureInfoCard, FeaturePill } from '../components';
import { Footer } from '../footer';
import { FakeChatWindow } from '../../fake-chat-window';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled('div', ({ $theme }) => ({
    color: $theme.foreground
}));

export const OnboardingPage = React.memo(function OnboardingPage(): JSX.Element {
    return (
        <Container>
            <InfoHeader/>
            <div style={{ height: 90 }}/>
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <div style={{ fontSize: 200, fontWeight: 'bold' }}>
                    Onboarding doesn&apos;t have to be a <GradientText>headache</GradientText>.
                </div>
            </div>
            <div style={{ height: 40 }}/>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    padding: 40,
                    gap: 20
                }}
            >
                <FeatureInfoCard
                    icon={faUsers}
                    title="One team, universal access"
                    description="Every feature on Hiyllo Work supports team based access control. Simply add new employees to the appropriate team and they'll have access to calendar events, task boards, chats, files, docs, and more."
                />
                <FeatureInfoCard
                    icon={faTransporter2}
                    title="Time waits for no one"
                    description="No more waiting for users to complete onboarding. You can add teams, assign tasks, start DMs, and more, all before they've even logged in for the first time."
                />
                <FeatureInfoCard
                    icon={faLink}
                    title="A one stop shop"
                    description="Hiyllo Work links to popular workforce and compensation management tools, allowing you to add employees to external HR systems as well as add them to Hiyllo Work in one go."
                    comingSoon
                />
            </div>
            <Footer/>
        </Container>
    );
});
