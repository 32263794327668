import React from 'react';
import { InfoHeader } from '../header';
import { styled } from '@hiyllo/ux/styled';
import { Typography } from '@hiyllo/ux/typography';
import { GradientText } from '../../landing';
import { faAt, faComment, faCrateEmpty, faDiagramNested, faFont, faHashtag, faListCheck, faPaperclip, faReply, faRunning, faSearch, faSmileWink, faSortSizeDown, faSquareKanban, faThumbTack, faUpDown, faUser, faUsers } from '@fortawesome/pro-light-svg-icons';
import { ChatThreadIcon, NymblIcon, VideoMeetingIcon } from '@hiyllo/icons/main';
import { FeatureInfoCard, FeaturePill } from '../components';
import { Footer } from '../footer';
import { FakeChatWindow } from '../../fake-chat-window';
import { motion } from 'framer-motion';

const Container = styled('div', ({ $theme }) => ({
    color: $theme.foreground
}));

export const TasksPage = React.memo(function TasksPage(): JSX.Element {
    return (
        <Container>
            <InfoHeader/>
            <div style={{ height: 90 }}/>
            <div
                style={{
                    height: '75vh',
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative'
                }}
            >
                <div style={{ overflow: 'hidden', borderRadius: 5, boxShadow: '0px 0px 20px 20px rgba(0,0,0,0.25)' }}>
                    <video
                        src="/media/tasks-rearranging.mov"
                        autoPlay
                        loop
                        muted
                        controls={false}
                        style={{
                            margin: -2,
                            height: '50vh',
                            width: 'auto',
                            objectFit: 'contain',
                            borderRadius: 5
                        }}
                    />
                </div>
                <div style={{ height: 40 }}/>
                <Typography.Header style={{ textAlign: 'center' }}>Work Management<br/>without the <GradientText>headache</GradientText>.</Typography.Header>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    padding: 40,
                    gap: 20
                }}
            >
                <FeatureInfoCard
                    icon={faListCheck}
                    title="Easy for Individual Contributors"
                    description={'As an IC, you can easily see what\'s on your plate from the "Your Tasks" page. Filter by tasks in active sprints (even if you\'re in multiple) or show all your tasks.'}
                />
                <FeatureInfoCard
                    icon={faUsers}
                    title="Easy for Managers"
                    description='As a manager, you can access kanban views of entire projects and sprints out-of-the box, or view the kanban board for an IC.'
                />
                <FeatureInfoCard
                    icon={faRunning}
                    title="Sprint through sprint planning"
                    description="Hiyllo Work features a streamlined sprint planning experience, quickly highlighting tasks that are unassigned or need sizing, as well as showing an overview of each team member's workload"
                />
            </div>
            <div>
                <Typography.Header style={{ textAlign: 'center' }}>One platform, one ecosystem</Typography.Header>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    padding: 40,
                    gap: 20
                }}
            >
                <FeatureInfoCard
                    icon={faComment}
                    title="Chat"
                    description='Send a link to a task in chat and it appear inline, and is actionable without even needing to leave your chat'
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 20,
                    paddingTop: 30
                }}
            >
                <Typography.Header>Packed with all the features you could ever need.</Typography.Header>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 10,
                        maxWidth: '70vw',
                        paddingBottom: 50
                    }}
                >
                    <FeaturePill
                        icon={faSquareKanban}
                        label='Kanban View'
                    />
                    <FeaturePill
                        icon={faUpDown}
                        label='Prioritization'
                    />
                    <FeaturePill
                        icon={faSortSizeDown}
                        label='Sizing'
                    />
                    <FeaturePill
                        icon={faPaperclip}
                        label='Task Attachments'
                    />
                    <FeaturePill
                        icon={faRunning}
                        label='Sprints'
                    />
                    <FeaturePill
                        icon={faDiagramNested}
                        label='Dependencies'
                    />
                </div>
            </div>
            <Footer/>
        </Container>
    );
});
