import React from 'react';
import { Regal } from './regal';
import { InlineLink } from '@hiyllo/ux/inline-link';

export const ReferralTerms = React.memo(function ReferralTerms(): JSX.Element {
    return (
        <Regal.Root>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png" style={{ height: 30 }}/>
                <div style={{ fontSize: 30, fontWeight: 'bold' }}>
                    Hiyllo Business
                </div>
            </div>
            <div>
                <h2>Hiyllo Business Referral Program Terms</h2>
            </div>
            <div>Last Updated: January 1st, 2025</div>
            <Regal.Content title="Definitions">
                <Regal.Content title="Hiyllo">
                    <Regal.Block>
                        Hiyllo Inc, a Delaware corporation, and its subsidiaries as applicable.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Entity">
                    <Regal.Block>
                        A natural person or a corporate entity.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Referral Partner">
                    <Regal.Block>
                        The Entity that refers clients to Hiyllo&apos;s range of products, who has met the requirements for enrollment under <Regal.Ref to="Enrollment Terms"/> and completed enrollment.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Hiyllo Business">
                    <Regal.Block>
                        A subset of B2B digital products offered by Hiyllo that are designed for businesses.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Customer">
                    <Regal.Block>
                        A third-party Entity that purchases Hiyllo Business products.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Subscription Inception">
                    <Regal.Block>
                        The moment when a Customer enters creates a subscription for a given Hiyllo Business product. A Customer may have multiple subscriptions with Hiyllo, each subscription has its own Subscription Inception.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Referral Token">
                    <Regal.Block>
                        A digital token saved in the web browser client of a Customer that indicates what Referral Link the Customer visited last
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Partner Portal">
                    <Regal.Block>
                        A online web portal where the Referral Partner may view their referral statistics, generate discount codes, and cash out Hiyllo Points.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Hiyllo Points">
                    <Regal.Block>
                        A digital balance, saved via the Partner Portal, issued by Hiyllo that can be redeemed for cash, wherein each Hiyllo Point is equivalent to one cent USD.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Pending Hiyllo Points">
                    <Regal.Block>
                        A form of Hiyllo Points which may not be cashed out until certain conditions are met
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Referred Subscription">
                    <Regal.Block>
                        A Customer&apos;s subscription for a given service that has been attributed to a specific Referral Partner per the terms of <Regal.Ref to="Requirements for successful referral"/>.
                    </Regal.Block>
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title="Introduction">
                <Regal.Block>
                    These terms govern the relationship between Hiyllo and the Referral Partner; where the two parties are entering into an agreement under which
                    the Referral Partner will engage in the referral of Customers to Hiyllo&apos;s Hiyllo Business line of products.
                </Regal.Block>
            </Regal.Content>
            <Regal.Content title="Enrollment Terms">
                <Regal.Content title="Initial Terms of Eligibility">
                    <Regal.Block>
                        In order to be eligible to enroll in the Hiyllo Business Referral Program, the Referral Partner must be one of:
                        <Regal.OL>
                            <div>
                                A natural person, at least 18 years of age; or
                            </div>
                            <div>
                                A corporate entity, duly organized and in good standing under the laws of its jurisdiction
                            </div>
                        </Regal.OL>
                    </Regal.Block>
                    <Regal.Block>
                        Hiyllo may, in its sole discretion, deny enrollment or declare ineligibility to any Referral Partner, for any reason or without a reason.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Process for Enrollment">
                    <Regal.Block>
                        An eligible entity may enroll in the Hiyllo Business Referral Program by completing the enrollment form on the Hiyllo Business website.
                    </Regal.Block>
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title="Referral Terms">
                <Regal.Content title="Requirements for successful referral">
                    <Regal.Block>
                        In order for a subscription by a Customer to a Hiyllo Business product to be considered a Referred Subscription for a Referral Partner, all of the following conditions must be satisfied:
                    </Regal.Block>
                    <Regal.Content>
                        <>
                            The Customer must either:
                            <Regal.OL kind='i'>
                                <div>
                                    Have a Referral Token saved in their web browser (pursuant to <Regal.Ref to="Saving of referral tokens"/>) at the time of Subscription Inception
                                </div>
                                <div>
                                    Use a discount code generated by the Referral Partner via their Partner Portal at the time of Subscription Inception AND not have a Referral Token saved in their web browser
                                </div>
                            </Regal.OL>
                        </>
                    </Regal.Content>
                    <Regal.Content>
                        The Customer must not have had a previous direct relationship with Hiyllo
                    </Regal.Content>
                    <Regal.Content>
                        The Customer must be creating a subscription to one of the following products:
                        <Regal.OL kind='i'>
                                <div>
                                    Hiyllo Work
                                </div>
                                <div>
                                    Hiyllo Support
                                </div>
                            </Regal.OL>
                    </Regal.Content>
                    <Regal.Block>
                        If all conditions are met, the subscription shall be attributed to the Referral Partner (Referred Subscription).
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Saving of referral tokens">
                    <Regal.Block>
                        A Referral Token is saved in the Customer&apos;s web browser when the Customer visits a Referral Link. The Referral Token can be saved indefinitely, however a Referral Token may be removed from the Customer&apos;s web browser under the following conditions:
                    </Regal.Block>
                    <Regal.Content>
                        The Customer clears their browser cache, or otherwise (intentionally or unintentionally) removes the Referral Token from their web browser
                    </Regal.Content>
                    <Regal.Content>
                        The Customer utilizes an ephemeral browsing session (incognito mode, private browsing, etc) to visit a Referral Link
                    </Regal.Content>
                    <Regal.Content>
                        The Customer visits a different Referral Link, which will overwrite the Referral Token saved in the Customer&apos;s web browser
                    </Regal.Content>
                    <Regal.Block>
                        There are other rare circumstances under which a Referral Token may be removed from the Customer&apos;s web browser, which may not be listed here. In any circumstance, if a Referral Token is not saved in the Customer&apos;s web browser, the Referral Partner will not be compensated for the referral.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Expiry of referral tokens">
                    <Regal.Block>
                        A Referral Token will expire 30 days after it is saved in a Customer&apos;s web browser. If a Customer revisits a Referral Link after the Referral Token has expired, the Referral Token will be saved again, and the 30 day period will restart.
                    </Regal.Block>
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title="Compensation Terms">
                <Regal.Content title="Issuance of compensation">
                    <Regal.Block>
                        Every time Hiyllo charges a Customer for a Referred Subscription, the Referral Partner will be issued one Pending Hiyllo Point for every cent of eligible revenue, which after 30 days shall be converted to Hiyllo Points.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Eligible revenue">
                    <Regal.Block>
                        The eligible revenue shall be calculated as the percentage of funds collected from the Customer minus any exemptions (wherein the percentage is applied after the exemptions are deducted). If the revenue is not in the form of USD, the collected funds shall be converted into USD at the time of collection, and the calculation of eligible revenue shall be performed after the conversion. The exact percentage shall be determined per <Regal.Ref to="Variable Revenue Share Plan"/>
                    </Regal.Block>
                    <Regal.Block>
                        The following are exempt from the calculation of eligible revenue:
                    </Regal.Block>
                    <Regal.Content>
                        Taxes
                    </Regal.Content>
                    <Regal.Content>
                        Metered charges for storage, bandwidth, or other resources
                    </Regal.Content>
                </Regal.Content>
                <Regal.Content title="Revocation of Pending Hiyllo Points">
                    <Regal.Block>
                        If the funds for which any Pending Hiyllo Points were issued is refunded, chargedback, or otherwise taken from Hiyllo, the Pending Hiyllo Points shall be revoked.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Early maturation">
                    <Regal.Block>
                        Hiyllo, in its sole discretion, may choose to convert Pending Hiyllo Points to Hiyllo Points before the 30 day period has elapsed. If this occurs, the Referral Partner may cash out the Hiyllo Points immediately.
                    </Regal.Block>
                </Regal.Content>
                <Regal.Content title="Variable Revenue Share Plan">
                    <Regal.Block>
                        Hiyllo shall establish a matrix of percentages which shall be displayed in the Partner Portal that determine, based on the amount of time that has elapsed since a given Subscription Inception, the percentage of eligible revenue that is issued to the Referral Partner as compensation (&quot;Revenue Share Plan&quot;). Hiyllo may modify the Revenue Share Plan at any time and shall notify the Referral Partner via email no fewer than 7 calendar days before the change takes effect.
                    </Regal.Block>
                    <Regal.Content title="Offering of performance based increases">
                        Hiyllo, at its sole discretion, may offer a performance based increase in the percentages under the Revenue Share Plan to the Referral Partner. This offering, displayed via the Partner Portal, shall guarantee said increase if the Referral Partner meets a certain criteria. If the Referral Partner&apos;s Revenue Share Plan has been set due to a performance based percentage offering and Hiyllo wishes to change the Revenue Share Plan, the Referral Partner must instead be notified via email no fewer than 30 calendar days before the change takes effect.
                    </Regal.Content>
                    <Regal.Content title='Lock-in at subscription inception'>
                        At Subscription Inception, the matrix that determines the compensation the Referral Partner receives for the specific Referred Subscription is fixed at the Referral Partner&apos;s current Variable Revenue Share Plan. Any modifications to the Revenue Share Plan after Subscription Inception shall not affect the compensation for any existing Referred Subscriptions.
                    </Regal.Content>
                </Regal.Content>
                <Regal.Content title="Cashing out">
                    <Regal.Block>
                        The Referral Partner may cash out their Hiyllo Points at any time, subject to a minimum cash out amount of $10. This may be completed via the Partner Portal, and Hiyllo will initiate the transfer no more than 24 hours after the request is submitted. Cash outs may be completed via ACH or Wire Transfer. The Referral Partner is responsible for any fees associated with the cash out.
                    </Regal.Block>
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title='Prohibited Activities'>
                <Regal.Block>
                    The Referral Partner shall not engage in any of the following activities:
                </Regal.Block>
                <Regal.OL>
                    <div>
                        The use of deceptive practices or any other form of manipulation to encourage Customers to visit a Referral Link
                    </div>
                    <div>
                        Mass contact communications (the usage of large contact lists, for which the Referral Partner has not obtained consent to contact) to encourage Customers to visit a Referral Link
                    </div>
                </Regal.OL>
            </Regal.Content>
            <Regal.Content title='Termination'>
                <Regal.Block>
                    Either party may terminate this agreement at any time, for any reason, with or without notice. Upon termination, the Referral Partner shall be compensated under the following terms:
                </Regal.Block>
                <Regal.OL kind='i'>
                    <div>
                        If Hiyllo has terminated this agreement as a result of a violation of any terms of this agreement, or any other agreements entered into with Hiyllo, the Referral Partner shall forefit all Hiyllo Points and Pending Hiyllo Points and receive no compensation
                    </div>
                    <div>
                        Under all other circumstances, the Referral Partner may cash out all Hiyllo Points subject to the terms of <Regal.Ref to="Cashing out"/>. Once any Pending Hiyllo Points are converted to Hiyllo Points, the Referral Partner may cash out the Hiyllo Points.
                    </div>
                </Regal.OL>
                <Regal.Block>
                    Any Hiyllo Points not cashed out within 90 days of issuance, conversion (from Pending Hiyllo Points), or the date of termination (whichever is later) shall be forefit.
                </Regal.Block>
            </Regal.Content>
            <Regal.Content title='Additional Terms'>
                <Regal.OL>
                    <div>
                        The Referral Partner, by entering into this agreement, certifies they have read and agree to <InlineLink href="/terms-of-service" label='The Hiyllo Business Terms of Service'/>
                    </div>
                    <div>
                        The Referral Partner, by entering into this agreement, certifies they have read and agree to <InlineLink href="/privacy-policy" label='The Hiyllo Business Privacy Policy'/>
                    </div>
                </Regal.OL>
            </Regal.Content>
            <Regal.Content title='Amendment of this agreement'>
                <Regal.Block>
                    Hiyllo may, at its sole discretion, amend this agreement at any time. The Referral Partner shall be notified via email at least 30 days before any amendment is to take effect. If the Referral Partner does not agree to the amendment, they may terminate this agreement under the terms of <Regal.Ref to="Termination"/>. If the Referral Partner does not terminate this agreement, the Referral Partner shall be deemed to have accepted the amendment.
                </Regal.Block>
            </Regal.Content>
            <Regal.Content title='Governing Law'>
                This agreement shall be goverened by the laws of Mercer County, New Jersey, United States.
            </Regal.Content>
        </Regal.Root>
    );
});
