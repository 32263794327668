import { faFileUpload,
  faChevronDown,
  faChevronsDown,
  faChevronsUp,
  faChevronUp,
  faEquals,
  faUserSlash,
  faMouse,
  faArrowPointer,
  faUserPlus,
  faFolder,
  faEnvelope,
  faCalendar,
  faSquareKanban,
  faComments
} from '@fortawesome/pro-light-svg-icons';
import {
  ContextMenuContainer,
  ContextMenuItem,
  useContextMenu
} from '@hiyllo/ux/context-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NymblIcon, VideoMeetingIcon } from '@hiyllo/icons/main';
import { ListTasksSlimTaskType } from '@hiyllo/omni-common/src/types/tasks/task-item';
import { TaskPillsRow } from '@hiyllo/omni-tasks/main';
import { styled } from '@hiyllo/ux/styled';
import { useTheme } from '@hiyllo/ux/theme';
import { motion, useMotionValueEvent, useScroll, useTransform } from 'framer-motion';
import React from 'react';
import { EJSONCache } from '@hiyllo/ejson-cache/main';
import { TaskPill } from '@hiyllo/omni-tasks/main/src/ui/common';
import { GradientText } from './landing';

const Container = styled('div', ({ $theme }) => ({
    background: $theme.background1,
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    pointerEvents: 'none'
}));

const InputBar = styled('div', ({ $theme }) => ({
    background: 'rgb(40,40,44)'
}));

const Separator = styled('div', ({ $theme }) => ({
    height: 1,
    background: $theme.midground1
}));

const Input = styled('input', ({ $theme }) => ({
    height: 60,
    width: 'calc(100% - 20px)',
    background: 'transparent',
    border: 'none',
    padding: 0,
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: $theme.foreground,
    fontFamily: 'hiyllo',
    fontSize: 17.5
}));

const Toolbar = styled('div', ({ $theme }) => ({
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    paddingLeft: 10,
    paddingRight: 10
}));

const Avatar = styled('img', ({ $theme }) => ({
    width: 40,
    height: 40,
    borderRadius: 10,
    objectFit: 'cover'
}));

const MessageName = styled('div', ({ $theme }) => ({
    color: $theme.foreground,
    fontFamily: 'hiyllo',
    fontSize: 15,
    fontWeight: 'bold'
}));

const MessageText = styled('div', ({ $theme }) => ({
    color: $theme.foreground,
    fontFamily: 'hiyllo',
    fontSize: 15
    // fontWeight: 'bold'
}));

const Message = React.memo(function Message(props: {
    userImage: string;
    name: string;
    text: string;
}): JSX.Element {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <Avatar src={props.userImage}/>
            <div>
                <MessageName>{props.name}</MessageName>
                <MessageText>{props.text}</MessageText>
            </div>
        </div>
    );
});

const Dock = styled('div', ({ $theme }) => ({
    background: $theme.background1,
    width: 80,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center'
}));

const Sidebar = styled('div', ({ $theme }) => ({
  background: $theme.background2,
  width: 280,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  alignItems: 'center',
  justifyContent: 'flex-start'
}));

const PriorityColorMap = (): Record<number, string> => ({
  0: '#d32f2f', // Red
  1: '#f57c00', // Orange
  2: '#1976d2', // Blue
  3: '#00796b', // Aqua
  4: '#388e3c' // Green
});

export const TinyPillContainer = styled<
  'div',
  { overdue?: boolean; noLeftPadding?: boolean }
>('div', ({ overdue, noLeftPadding }) => ({
  background: overdue ? '#d3302f' : 'rgba(255, 255, 255, 0.2)',
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
  alignItems: 'center',
  borderRadius: 20,
  paddingLeft: noLeftPadding === true ? 0 : 10,
  fontSize: 12,
  overflow: 'hidden'
}));

const ImageLeftHalfCard = React.memo(function ImageHalfCard(props: {
  imageSrc: string;
  content: JSX.Element
}): JSX.Element {
  return (
    <div style={{ marginBottom: IS_MOBILE ? 80 : 0, marginLeft: IS_MOBILE ? 20 : 0, overflow: 'hidden', gap: 40, height: IS_MOBILE ? '' : '100vh', display: 'flex', flexDirection: IS_MOBILE ? 'column' : 'row', width: IS_MOBILE ? 'calc(100% - 100px)' : '100%', alignItems: 'center', flexWrap: 'wrap' }}>
      <motion.div
        style={{
          marginLeft: IS_MOBILE ? 0 : 40,
          boxShadow: 'rgba(0,0,0,0.75) 0px 0px 50px -5px',
          borderRadius: 10,
          overflow: 'hidden'
        }}
      >
        <img
          src={props.imageSrc}
          style={{
            width: IS_MOBILE ? '100%' : '60vw',
            height: 'auto',
            borderRadius: 5
          }}
        />
      </motion.div>
      <motion.div style={{ width: IS_MOBILE ? '100%' : 0, flexGrow: 1 }}>
        {props.content}
      </motion.div>
    </div>
  );
});

export const PriorityIconsMap = {
  0: faChevronsUp,
  1: faChevronUp,
  2: faEquals,
  3: faChevronDown,
  4: faChevronsDown
};

const JumboText = styled('div', ({ $theme }) => ({
  fontSize: IS_MOBILE ? 42 : 48
  // marginBlock: 10
}));

export const PillContent = styled<'div', { overdue?: boolean }>(
  'div',
  ({ overdue }) => ({
    backgroundColor: overdue ? '#f44336' : '#5f5e62',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 20,
    gap: 5,
    cursor: 'pointer',
    height: 26
  })
);

export const CondensedDetailsPill = React.memo(
  function CondensedDetailsPill(props: {
    task: Omit<ListTasksSlimTaskType, 'assignee'>;
  }): JSX.Element {
    return (
      <TinyPillContainer style={{ paddingLeft: 0, borderRadius: 5 }}>
        <div
          style={{
            height: 25,
            backgroundColor: PriorityColorMap()[props.task.priority],
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: 5,
            paddingLeft: 5,
            fontSize: 12.5
          }}
        >
          <FontAwesomeIcon
            icon={PriorityIconsMap[props.task.priority]}
            fixedWidth
          />
        </div>
        <div style={{ paddingRight: 5 }}>{props.task.shortId}</div>
      </TinyPillContainer>
    );
  }
);

const IS_MOBILE = window.innerHeight > window.innerWidth;

const cache = new EJSONCache({
  getItem: async () => {
    return null;
  },
  setItem: async () => {
    //
  }
});

const Frame = styled('div', ({ $theme }) => ({
  height: IS_MOBILE ? '100vh' : '100vh',
  paddingLeft: IS_MOBILE ? 100 : 0,
  width: IS_MOBILE ? 'calc(100vw - 0px)' : '100vw',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexShrink: 0,
  flexGrow: 0,
  position: 'relative'
}));

export const ScrollFeatures = React.memo(function ScrollFeatures(): JSX.Element {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    container: { current: document.getElementById('root') }
  });
  const captionScroll = useTransform(scrollYProgress, [0, 1], [0, -(window.innerWidth * 5)]);
  const iconsOpacity = useTransform(scrollYProgress, [0, 0.025, 0.99, 1], [0, 1, 1, 1]);
  const featureTV = useTransform(scrollYProgress, [0, 1 / 6, 2 / 6, 3 / 6, 4 / 6, 5 / 6, 1], ['chat', 'chat', 'tasks', 'calendar', 'mail', 'stuff', 'meet']);
  const [feature, setFeature] = React.useState<'chat' | 'tasks' | 'calendar' | 'mail' | 'stuff' | 'meet'>('chat');
  useMotionValueEvent(featureTV, 'change', v => {
    setFeature(v as 'chat' | 'tasks' | 'calendar' | 'mail' | 'stuff' | 'meet');
  });
  const [fixed, setFixed] = React.useState(false);
  const [bottom, setBottom] = React.useState(false);
  useMotionValueEvent(scrollYProgress, 'change', v => {
    if (v !== 0 && v !== 1) {
      setFixed(true);
    } else {
      if (v === 1) {
        setBottom(true);
      } else {
        setBottom(false);
      }
      setFixed(false);
    }
  });

  return (
    <div style={{ pointerEvents: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '600vh', position: 'relative', overflow: 'hidden' }} ref={ref}>
      <motion.div style={IS_MOBILE ? {} : { [bottom ? 'bottom' : 'top']: bottom ? 'env(safe-area-inset-bottom)' : 0, position: fixed ? 'fixed' : 'absolute', height: '100vh' }}>
        <motion.div style={IS_MOBILE ? { display: 'flex', flexDirection: 'column' } : { translateX: captionScroll, display: 'flex', flexDirection: IS_MOBILE ? 'column' : 'row', height: '100vh', width: '100vw' }}>
          <Frame>
            <ImageLeftHalfCard
              imageSrc="/ss/chat-with-last-read.png"
              content={
                <>
                  <JumboText>Chat</JumboText>
                  <div style={{ lineHeight: '1.2em', fontSize: 18, maxWidth: '75%' }}>All the bells and whistles, from reactions and pinned messages to <GradientText>AI-generated summaries</GradientText></div>
                </>
              }
            />
          </Frame>
          <Frame>
            <ImageLeftHalfCard
              imageSrc="/ss/kanban-project.png"
              content={
                <>
                  <JumboText>Task Management</JumboText>
                  <div style={{ lineHeight: '1.2em', fontSize: 18, maxWidth: '75%' }}>Easy to grasp for individual contributors, yet packed with powerful features for project managers and leadership</div>
                </>
              }
            />
          </Frame>
          <Frame>
            <ImageLeftHalfCard
              imageSrc="/ss/calendar.png"
              content={
                <>
                  <JumboText>Calendar</JumboText>
                  <div style={{ lineHeight: '1.2em', fontSize: 18, maxWidth: '75%' }}>
                    Intuitively create events, schedule meetings, and mark busy times. Team members can use <img src="https://solo.hiyllo.io/favicon.png" style={{ height: '0.7em', width: 'auto' }}/> Hiyllo Solo for free to sync with their personal schedules, including Google Calendar and Microsoft Outlook, without exposing company data.
                  </div>
                </>
              }
            />
          </Frame>
          <Frame>
            <ImageLeftHalfCard
              imageSrc="/mail-screenshot.png"
              content={
                <>
                  <JumboText>Mail</JumboText>
                  <div style={{ lineHeight: '1.2em', fontSize: 18, maxWidth: '75%' }}>
                  We got rid of all the shiny objects that make email clients bloated and slow, giving you a simple and secure mail system fit for everyone— from engineers to salespeople. We also developed upon features like shared mailboxes, which allow multiple users or teams to send and receive mail from the same address.
                  </div>
                </>
              }
            />
          </Frame>
          <Frame>
            <ImageLeftHalfCard
              imageSrc="/ss/stuff-list.png"
              content={
                <>
                  <JumboText>Stuff</JumboText>
                  <div style={{ lineHeight: '1.2em', fontSize: 18, maxWidth: '75%' }}>
                    Hiylo Work has a place for your company&apos;s stuff (by which we mean images, videos, all kinds of files really + super aesthetic collaborative docs).
                  </div>
                </>
              }
            />
            {!IS_MOBILE
? <div style={{
              position: 'absolute',
              left: '32.5%',
              top: '52.5%'
            }}>
              <img src="/ss/stuff-docs.png" style={{ width: '30vw', height: 'auto', boxShadow: 'rgba(0,0,0,0.75) 0px 0px 50px -5px',
              borderRadius: 10,
              overflow: 'hidden' }}/>
            </div>
            : null}
          </Frame>
          <Frame>
            <ImageLeftHalfCard
              imageSrc="/ss/meet.png"
              content={
                <>
                  <JumboText>Meet</JumboText>
                  <div style={{ lineHeight: '1.2em', fontSize: 18, maxWidth: '75%' }}>
                    Crystal-clear video meetings, that can be joined from anywhere across the platform with a single click.
                  </div>
                </>
              }
            />
          </Frame>
        </motion.div>
        <motion.div style={IS_MOBILE ? { paddingLeft: 17.5, opacity: iconsOpacity, [bottom ? 'bottom' : 'top']: 0, position: fixed ? 'fixed' : 'absolute', left: 0, width: IS_MOBILE ? '' : '100%', height: '100vh', display: 'flex', flexDirection: 'row' } : { bottom: bottom ? 'env(safe-area-inset-bottom)' : 0, position: fixed ? 'fixed' : 'absolute', left: 0, width: IS_MOBILE ? '' : '100%', height: 100, display: 'flex', flexDirection: 'row' }}>
          <div style={{ paddingBottom: IS_MOBILE ? 0 : 40, fontSize: IS_MOBILE ? 25 : 30, display: 'flex', flexDirection: IS_MOBILE ? 'column' : 'row', gap: 10, alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <motion.div animate={{ opacity: feature === 'chat' ? 1 : 0.5 }}>
              <FontAwesomeIcon icon={faComments} fixedWidth/>
            </motion.div>
            <motion.div animate={{ opacity: feature === 'tasks' ? 1 : 0.5 }}>
              <FontAwesomeIcon icon={faSquareKanban} fixedWidth/>
            </motion.div>
            <motion.div animate={{ opacity: feature === 'calendar' ? 1 : 0.5 }}>
              <FontAwesomeIcon icon={faCalendar} fixedWidth/>
            </motion.div>
            <motion.div animate={{ opacity: feature === 'mail' ? 1 : 0.5 }}>
              <FontAwesomeIcon icon={faEnvelope} fixedWidth/>
            </motion.div>
            <motion.div animate={{ opacity: feature === 'stuff' ? 1 : 0.5 }}>
              <FontAwesomeIcon icon={faFolder} fixedWidth/>
            </motion.div>
            <motion.div animate={{ opacity: feature === 'meet' ? 1 : 0.5 }}>
              <VideoMeetingIcon width={IS_MOBILE ? 25 : 30} color="white"/>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
});
