import React from 'react';
import { InlineLink } from '@hiyllo/ux/inline-link';

export const Footer = React.memo(function Footer(): JSX.Element {
  return (
    <div style={{ textAlign: 'center', padding: 20, fontSize: 12 }}>
      <div>
        <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png" style={{ height: 20, width: 20, opacity: 0.75, paddingBottom: 10 }}/>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 20, justifyContent: 'center', alignItems: 'center' }}>
        <div>&copy; Hiyllo Inc 2025, All Rights Reserved</div>
        <InlineLink href='/privacy-policy' label='Privacy Policy' />
        <InlineLink href='mailto:support@hiyllo.io' label='support@hiyllo.io' />
      </div>
    </div>
  );
});
