/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import moment from 'moment';
import { Input } from '@hiyllo/ux/input';
import { Card } from '@hiyllo/ux/surface';
import { styled } from '@hiyllo/ux/styled';
import { Button } from '@hiyllo/ux/button';
import { MoopsyError } from '@moopsyjs/core';
import { useShowAlert, useShowDialog } from '@hiyllo/ux/dialogs';
import { Typography } from '@hiyllo/ux/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBank, faFileContract, faGift, faMessage } from '@fortawesome/pro-light-svg-icons';

import * as GetPromotionBP from '../../../blueprints/promotions/get-promotion';
import * as CreateProductBP from '../../../blueprints/products/create-product';

import { PageWithNavbar } from '../../common';
import { seamlessAuth, seamlessClient } from '../../../seamless';
import { ProductKind } from '../../../types/product';
import { PromotionCodeType } from '../../../types/promotion-code';
import { useParams } from 'react-router-dom';
import { PlanKind } from '../../../types/subscription';
import { SUPPORT_BASIC_PLAN, SUPPORT_PRO_PLAN, WORKSPACE_STANDARD_PLAN } from '../../../consts/plans';

export const GradientText = styled('div', ({ $theme }) => ({
  background: $theme.buttonBackground,
  backgroundClip: 'text',
  color: 'transparent',
  WebkitBackgroundClip: 'text'
}));

const Row = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 10
});

function usePromotion (code: string | null): PromotionCodeType | null {
  const [promotion, setPromotion] = React.useState<PromotionCodeType | null>(null);
  const getPromotionMutation = seamlessClient.useMutation<GetPromotionBP.Plug>(GetPromotionBP);

  React.useEffect(() => {
    if (code != null) {
      void getPromotionMutation.call({ code }).then((res) => {
        setPromotion(res.promotion);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return promotion;
}

export const CreateProduct = React.memo(function CreateProduct (props: {kind: ProductKind, initial?: boolean}): JSX.Element {
  const authState = seamlessAuth.useAuthState();
  const createProductMutation = seamlessClient.useMutation<CreateProductBP.Plug>(CreateProductBP);
  const basePromotionCode = new window.URLSearchParams(window.location.search).get('promotion') ?? 'freetrial';
  const [variant, setVariant] = React.useState<PlanKind | null>(props.kind === ProductKind.work ? PlanKind.work_standard : null);

  const [promotionCode, setPromotionCode] = React.useState<string>(basePromotionCode);
  const promotion = usePromotion(promotionCode);
  const showDialog = useShowDialog();
  const [slug, setSlug] = React.useState<string>(props.initial === true ? (authState?.federation ?? '') : '');
  const showAlert = useShowAlert();

  const onClickChangePromotionCode = React.useCallback(() => {
    void showDialog({
      title: 'Got a promo code?',
      message: "Enter it here. If it's valid, we'll apply it. Contact support@hiyllo.io if you have any issues",
      requireValue: true,
      onSubmit: (value) => {
        setPromotionCode(value.toLowerCase());
      }
    });
  }, [showDialog]);

  const onSubmit = React.useCallback(() => {
    if (variant == null) {
      void showAlert({
        title: 'Please select a plan',
        message: 'You must select a plan before proceeding'
      });
      return;
    }

    void createProductMutation.call({
      kind: props.kind,
      promotionCode,
      affiliate: window.localStorage.getItem('affiliate') ?? null,
      plan: variant,
      slug
    }).then((res) => {
      window.location.href = res.href;
    }).catch((err) => {
      void showAlert({
        title: 'Unable to activate...',
        message: ((err as MoopsyError).message ?? 'There was an issue creating your organization') + '. Please contact sales@hiyllo.io for help.'
      });
    });
  }, [createProductMutation, promotionCode, props.kind, showAlert, slug, variant]);

  const plan = props.kind === ProductKind.work ? WORKSPACE_STANDARD_PLAN : (variant === PlanKind.support_basic ? SUPPORT_BASIC_PLAN : variant === PlanKind.support_pro ? SUPPORT_PRO_PLAN : null);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10, height: '100%' }}>
        <div style={{ flexBasis: '45%', flexGrow: 1, flexShrink: 0, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Typography.Header>
            <GradientText>
              Create your new {props.kind === ProductKind.work ? 'workspace' : 'support center'}
            </GradientText>
          </Typography.Header>
          <div style={{ height: 20 }}/>
          {props.kind === ProductKind.support ?
            <div>
              <Typography.Label>Choose your plan</Typography.Label>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                <Card color="background3">
                  <Typography.SubHeader>Support Basic</Typography.SubHeader>
                  <div style={{ fontWeight: 'bold' }}>$15 / month</div>
                  <div>Custom Domain: Additional $5 / month</div>
                  <div>❌ Hiyllo branding may not be disabled*</div>
                  <div style={{ display: 'inline-block', marginTop: 10 }}>
                    <Button
                      label={variant === PlanKind.support_basic ? 'Selected' : 'Select'}
                      onClick={() => setVariant(PlanKind.support_basic)}
                      isSecondary={variant === PlanKind.support_basic}
                    />
                  </div>
                </Card>
                <Card color="background3">
                  <Typography.SubHeader>Support Pro</Typography.SubHeader>
                  <div style={{ fontWeight: 'bold' }}>$30 / month</div>
                  <div>Custom Domain: Included</div>
                  <div>✅ Hiyllo branding can be disabled</div>
                  <div style={{ display: 'inline-block', marginTop: 10 }}>
                    <Button
                      label={variant === PlanKind.support_pro ? 'Selected' : 'Select'}
                      onClick={() => setVariant(PlanKind.support_pro)}
                      isSecondary={variant === PlanKind.support_pro}
                    />
                  </div>
                </Card>
              </div>
            </div>
          : null}
          <div style={{ height: 15 }}/>
          <div>
            <Typography.Label>Choose your hiyllo.{props.kind} domain</Typography.Label>
            <Input value={slug} onChangeValue={setSlug} placeholder='yourcompany' endEnhancer={props.kind === ProductKind.support ? '.hiyllo.support' : '.hiyllo.work'} inputStyle={{ textAlign: 'right' }}/>
          </div>
          {props.kind === 'support' ?
            <div style={{ fontSize: 12.5, marginTop: 5 }}>If you&apos;d like, you can add a custom domain once your support center is created</div>
          : null}
          <div style={{ height: 30 }}/>
          <div>
            <>
              <div style={{ fontSize: 12.5, paddingBottom: 7.5 }}>
                <FontAwesomeIcon icon={faBank}/> Legal Stuff
              </div>
              <div style={{ fontSize: 15 }}>By continuing up you agree:</div>
              <div>
                <ul style={{ fontSize: 15, paddingLeft: 15, paddingTop: 0, marginInlineStart: 0, marginTop: 5 }}>
                  <li>You have read and understand the billing terms stated on this page</li>
                  <li>You have read and agree to the <a href="/terms-of-service" target="_blank" style={{ color: 'white' }}>Hiyllo Work Terms of Service</a> (governs your relationship with Hiyllo)</li>
                  <li>You have read and agree to the <a href="/user-terms-of-use" target="_blank" style={{ color: 'white' }}>Platform Terms of Use</a> (governs usage of the platform by you and your authorized users)</li>
                  <li>You have read and agree to the <a href="/privacy-policy" target="_blank" style={{ color: 'white' }}>Privacy Policy</a></li>
                  <li>You have the authority to bind your Organization to these agreements</li>
                </ul>
              </div>
            </>
          </div>
          <div>
            <div style={{ display: 'inline-block' }}>
              <Button
                onClick={onSubmit}
                label="Activate"
                isLoading={createProductMutation.isLoading}
                isSecondary={
                  plan == null || slug.length < 3
                }
              />
            </div>
          </div>
        </div>
        <div style={{ flexBasis: '45%', flexShrink: 0, alignSelf: 'stretch' }}>
          <Card color="background2" style={{ height: 'calc(100% - 40px)' }}>
            {promotion?.grant != null && promotion.grant.duration.weeks > 0 ?
              <>
                <div style={{ fontSize: 12.5, paddingBottom: 7.5 }}>
                  <FontAwesomeIcon icon={faGift}/> Free Trial
                </div>
                <Row style={{ gap: 40, rowGap: 15, flexWrap: 'wrap' }}>
                  <div>
                    <b>You will receive a</b>
                    <GradientText style={{ fontSize: 30 }}>
                      {promotion.grant.duration.weeks} week free trial
                    </GradientText>
                  </div>
                  <div style={{ height: 10 }}/>
                  <div>
                    <b>Which will end on</b>
                    <GradientText style={{ fontSize: 30 }}>
                      {moment().add(promotion.grant.duration.weeks, 'weeks').format('MMMM Do, YYYY')}
                    </GradientText>
                  </div>
                </Row>
                <div style={{ paddingTop: 10, fontSize: 12.5, fontFamily: 'hiyllo' }}>
                  If you have a payment method attached to your account when your free trial ends, your subscription will start and you will be billed automatically
                </div>
                <div style={{ height: 30 }}/>
              </>
            : null}
            {plan != null ?
            <>
              <div style={{ fontSize: 12.5, paddingBottom: 7.5 }}>
                <FontAwesomeIcon icon={faFileContract}/> Pricing
              </div>
              {plan.type === 'work' ?
                <>
                  <Row style={{ gap: 40, rowGap: 15, flexWrap: 'wrap' }}>
                    <div>
                      <b>Price per user / month</b>
                      <GradientText style={{ fontSize: 30 }}>
                        $19.99
                      </GradientText>
                    </div>
                    <div>
                      <b>Included storage per user*</b>
                      <GradientText style={{ fontSize: 30 }}>
                        25gb
                      </GradientText>
                    </div>
                    <div>
                      <b>Additional storage</b>
                      <GradientText style={{ fontSize: 30 }}>
                        5&cent; per GB
                      </GradientText>
                    </div>
                  </Row>
                  <div style={{ paddingTop: 10, fontSize: 12.5, fontFamily: 'hiyllo' }}>
                    *storage is pooled and limits are enforced across your entire workspace, not individual users. Each additional user adds more storage to the workspace pool.
                  </div>
                </>
              :
                <>
                  <Row style={{ gap: 40, rowGap: 15, flexWrap: 'wrap' }}>
                    <div>
                      <b>Base price</b>
                      <GradientText style={{ fontSize: 30 }}>
                        ${plan.baseCostE2 / 100} / month
                      </GradientText>
                    </div>
                    <div>
                      <b>Price per admin<sup>1</sup></b>
                      <GradientText style={{ fontSize: 30 }}>
                        ${plan.costPerUser / 100} / admin / month
                      </GradientText>
                    </div>
                  </Row>
                  <div style={{ height: 15 }}/>
                  <Row style={{ gap: 40, rowGap: 15, flexWrap: 'wrap' }}>
                    <div>
                      <b>File Storage</b>
                      <GradientText>
                        <div style={{ fontSize: 27.5 }}>
                          {plan.costCentsPerGB}&cent; / gb / month
                        </div>
                        <div>
                        {plan.includedGB}gb included monthly for free
                        </div>
                      </GradientText>
                    </div>
                    <div>
                      <b>Visitors</b>
                      <GradientText>
                        <div style={{ fontSize: 27.5 }}>
                          ${(plan.costPer1KUniqueVisitorE2 / 100).toFixed(2)} / 1000 visitors<sup>2</sup> / month
                        </div>
                        <div>
                          {Intl.NumberFormat('en-US').format(plan.includedVisitorsK * 1000)} visitors included monthly for free
                        </div>
                      </GradientText>
                    </div>
                  </Row>
                  <div style={{ marginTop: 5, fontSize: 12.5 }}>
                    <div>
                      <sup>1</sup>Admins are people who manage the support center, like support agents or content authors. End users / customer accounts have no charge.
                    </div>
                    <div>
                      <sup>2</sup>Your usage is measured by the number of unique visitors. If the same user visits your support center multiple times in a month, they will only count for <b>1</b>. Note that technical limitations may prevent 100% accuracy.
                    </div>
                  </div>
                </>
              }
              <div style={{ display: 'inline-block', marginTop: 10 }}>
              <Button
                label="Got a promo code?"
                onClick={onClickChangePromotionCode}
                isSecondary
              />
              </div>
              <div style={{ height: 30 }}/>
            </>
            : null}
            <>
              <div style={{ fontSize: 12.5, paddingBottom: 7.5 }}>
                <FontAwesomeIcon icon={faMessage}/> Service Level Agreement
              </div>
              <Row style={{ gap: 40, rowGap: 20, flexWrap: 'wrap' }}>
                <div>
                  <b>Uptime:</b>
                  <GradientText style={{ fontSize: 30 }}>
                    99%
                  </GradientText>
                </div>
                <div>
                  <b>General Response Time</b>
                  <GradientText style={{ fontSize: 30 }}>
                    16 hours
                  </GradientText>
                </div>
                <div>
                  <b>Urgent Response Time</b>
                  <GradientText style={{ fontSize: 30 }}>
                    2 hours
                  </GradientText>
                </div>
              </Row>
              <div style={{ height: 20 }}/>
            </>
          </Card>
        </div>
      </div>
    </>
  );
});
