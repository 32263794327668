import React from 'react';

interface ContentMapType { [key: string]: number[] }

const SectionContext = React.createContext<number[]>([]);
const SectionNumberCtx = React.createContext<number | undefined>(undefined);
const ContentMapCtx = React.createContext<{
    value: ContentMapType;
    setValue: React.Dispatch<React.SetStateAction<ContentMapType>>;
}>({
    value: {},
    setValue: () => {
        //
    }
});

const Content = React.memo(function RegalContent(props: React.PropsWithChildren<{ section?: number, title?: string }>): JSX.Element {
    const currentContext = React.useContext(SectionContext);
    const impliedSection = React.useContext(SectionNumberCtx);
    const section = props.section ?? impliedSection;
    const contentMap = React.useContext(ContentMapCtx);

    React.useEffect(() => {
        if (section == null) {
            throw new Error('Section number is required');
        }

        const title = props.title;

        if (title == null) {
            return;
        }

        contentMap.setValue(prev => {
            const path = [...currentContext, section];
            return { ...prev, [title]: path };
        });
    }, []);

    const children = React.useMemo(() => {
        const _children = React.Children.toArray(props.children);
        let i = 0;

        return _children.map(child => {
            if (React.isValidElement(child) && child.type === Content) {
                i++;

                return (
                    <SectionNumberCtx.Provider key={child.key} value={i}>
                        {child}
                    </SectionNumberCtx.Provider>
                );
            } else {
                return child;
            }
        });
    }, [props.children]);

    if (section == null) {
        throw new Error('Section number is required');
    }

    if (currentContext.length === 0) {
        return (
            <SectionContext.Provider value={[...currentContext, section]}>
                <div style={{ marginTop: 20 }}>
                    <h3>{section}. {props.title}</h3>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        {React.Children.toArray(props.children).map((child, i) => (
                            <SectionNumberCtx.Provider key={i} value={i + 1}>
                                {child}
                            </SectionNumberCtx.Provider>
                        ))}
                    </div>
                </div>
            </SectionContext.Provider>
        );
    }

    if (currentContext.length === 1) {
        return (
            <SectionContext.Provider value={[...currentContext, section]}>
                <div style={{ paddingLeft: 15, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 10 }}>
                    <div style={{ width: 25, paddingTop: 2.5, flexShrink: 0 }}><b>{[...currentContext, section].join('.')}</b></div>
                    <div style={{ lineHeight: '1.2em' }}>
                        <b style={{ textDecoration: 'underline' }}>{props.title}</b> {children}
                    </div>
                </div>
            </SectionContext.Provider>
        );
    }

    if (currentContext.length === 2) {
        return (
            <div style={{ marginTop: 5, paddingLeft: 0, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 10 }}>
                <div style={{ width: 15 }}>
                    <b>
                        ({String.fromCharCode(96 + section)})
                    </b>
                </div>
                <div style={{ lineHeight: '1.2em' }}>
                    <b style={{ textDecoration: 'underline' }}>{props.title}</b> {props.children}
                </div>
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 10 }}>
            <div style={{ width: 15 }}>
                <b>
                    ({String.fromCharCode(97 + section)})
                </b>
            </div>
            <div style={{ lineHeight: '1.2em' }}>
                <b style={{ textDecoration: 'underline' }}>{props.title}</b> {props.children}
            </div>
        </div>
    );
});

const OL = React.memo(function OL(props: React.PropsWithChildren<{kind?: 'letter' | 'number' | 'i'}>): JSX.Element {
    const children = React.Children.toArray(props.children);
    return (
        <div style={{ paddingTop: 7.5, paddingBottom: 7.5, display: 'flex', flexDirection: 'column', gap: 10 }}>
            {children.map((child, i) => (
                <div key={i} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                    <div style={{ textAlign: 'right', width: 20, flexShrink: 0, flexGrow: 0 }}>
                        <b>
                            ({
                                props.kind === 'i' ?
                                    (new Array(i + 1).fill('i').join('')) :
                                props.kind === 'letter'
                                    ? String.fromCharCode(97 + i)
                                    : i + 1
                            })
                        </b>
                    </div>
                    <div>
                        {child}
                    </div>
                </div>
            ))}
        </div>
    );
});

const Root = React.memo(function Root (props: React.PropsWithChildren): JSX.Element {
    const [contentMap, setContentMap] = React.useState<{ [key: string]: number[] }>({});

    const children = React.useMemo(() => {
        const _children = React.Children.toArray(props.children);
        let i = 0;

        return _children.map(child => {
            if (React.isValidElement(child) && child.type === Content) {
                i++;

                return (
                    <SectionNumberCtx.Provider key={child.key} value={i}>
                        {child}
                    </SectionNumberCtx.Provider>
                );
            } else {
                return child;
            }
        });
    }, [props.children]);

    const contentMapCtxValue = React.useMemo(() => ({ value: contentMap, setValue: setContentMap }), [contentMap]);

    return (
        <ContentMapCtx.Provider value={contentMapCtxValue}>
            <div className="HiylloLegalDocument" style={{ color: 'white', padding: 20, fontFamily: 'hiyllo' }}>
                {children}
            </div>
        </ContentMapCtx.Provider>
    );
});

export function formatSectionPath(section: number[]): string {
    return section.map((v, i) => i === 2 ? String.fromCharCode(97 + v) : v).join('.');
}

export const Ref = React.memo(function Ref(props: { to: string; }) {
    const contentMap = React.useContext(ContentMapCtx);

    const section = contentMap.value[props.to];

    if (section == null) {
        return <div>??? MISSING REF ({props.to}) ???</div>;
    }

    return (
        <div style={{ display: 'inline-block' }}>§{formatSectionPath(section)}</div>
    );
});

export const Definition = React.memo(function Definition(props: React.PropsWithChildren<{ term: string }>) {
    return (
        <span style={{ display: 'inline-block' }}>
            {props.children} (&quot;{props.term}&quot;)
        </span>
    );
});

export const Block = React.memo(function Block(props: React.PropsWithChildren) {
    return (
        <div style={{ marginTop: 5 }}>
            {props.children}
        </div>
    );
});

export const Regal = { Root, Content, Ref, OL, Definition, Block };
