import React from 'react';
import { InfoHeader } from '../header';
import { styled } from '@hiyllo/ux/styled';
import { Typography } from '@hiyllo/ux/typography';
import { GradientText } from '../../landing';
import { faArrows, faAt, faBadgeCheck, faFont, faForward, faHashtag, faLock, faMailboxFlagUp, faMailForward, faReply, faSearch, faServer, faShield, faSmileWink, faSquareKanban, faThumbTack, faUser, faUsers } from '@fortawesome/pro-light-svg-icons';
import { ChatThreadIcon, NymblIcon, VideoMeetingIcon } from '@hiyllo/icons/main';
import { FeatureInfoCard, FeaturePill } from '../components';
import { Footer } from '../footer';
import { FakeChatWindow } from '../../fake-chat-window';
import { motion } from 'framer-motion';
import { useTheme } from '@hiyllo/ux/theme';

const Container = styled('div', ({ $theme }) => ({
    color: $theme.foreground
}));

const FakeMessage = React.memo(function FakeMessage(): JSX.Element {
    const $theme = useTheme();

    return (
        <div style={{ boxShadow: '0px 0px 20px 20px rgba(0,0,0,0.25)', borderRadius: 5, overflow: 'hidden', backgroundColor: $theme.background3, gap: 1.5, display: 'flex', flexDirection: 'column' }}>
            <div style={{ paddingLeft: 10, paddingRight: 10, backgroundColor: $theme.midground2, height: 35, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                <div style={{ height: 10, width: 10, borderRadius: '50%', background: $theme.buttonBackground }}/>
                <div style={{ width: 100 }}>ACME Inc</div>
                <div style={{ width: 10 }}/>
                <NymblIcon color='white' width={15}/>
                <div>ACME Inc confirms your order for 3 office chairs</div>
            </div>
            <div style={{ paddingLeft: 10, paddingRight: 10, backgroundColor: $theme.midground2, height: 35, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                <div style={{ height: 10, width: 10, borderRadius: '50%', background: $theme.buttonBackground }}/>
                <div style={{ width: 100 }}>Taylor Doe</div>
                <div style={{ width: 10 }}/>
                <NymblIcon color='white' width={15}/>
                <div>Taylor accepts your offer of discounted bulk-order pricing</div>
            </div>
            <div style={{ paddingLeft: 10, paddingRight: 10, backgroundColor: $theme.background3, height: 35, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                <div style={{ height: 10, width: 10, borderRadius: '50%' }}/>
                <div style={{ width: 100 }}>Hiyllo Team</div>
                <div style={{ width: 10 }}/>
                <NymblIcon color='white' width={15}/>
                <div>Hiyllo confirms your addition of 3 new user licenses</div>
            </div>
        </div>
    );
});

export const MailPage = React.memo(function MailPage(): JSX.Element {
    return (
        <Container>
            <InfoHeader/>
            <div style={{ height: 90 }}/>
            <div
                style={{
                    height: '75vh',
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative'
                }}
            >
                <div style={{ overflow: 'hidden' }}>
                    <FakeMessage/>
                </div>
                <div style={{ height: 40 }}/>
                <Typography.Header style={{ textAlign: 'center' }}>Mail on Hiyllo Work<br/>eliminates <GradientText>distractions</GradientText>.</Typography.Header>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    padding: 40,
                    gap: 20
                }}
            >
                <FeatureInfoCard
                    icon={NymblIcon}
                    title="AI-built in"
                    description='Nymbl can summarize incoming messages, saving you time and helping you stay focused'
                />
                <FeatureInfoCard
                    icon={faMailboxFlagUp}
                    title="Advanced mailboxes"
                    description='In addition to personal mailboxes for each user, you can create shared mailboxes that can be shared with other users or teams'
                />
                <FeatureInfoCard
                    icon={faShield}
                    title="Smarter spam detection"
                    description='Hiyllo spam detection is specially tuned for business use, helping to avoid genuine business emails from being marked as spam'
                />
                <FeatureInfoCard
                    icon={faBadgeCheck}
                    title="Your authentic self"
                    description='Hiyllo Mail utilizes DKIM and SPF, so recipients always know emails are from you'
                />
                <FeatureInfoCard
                    icon={faServer}
                    title="Hiyllo-powered"
                    description="Mail is dispatched via Hiyllo's own servers and IPs, which we keep clear of mass-marketers and spammers, not third-party mail services"
                />
            </div>
            {/* <div>
                <Typography.Header style={{ textAlign: 'center' }}>One platform, one ecosystem</Typography.Header>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    padding: 40,
                    gap: 20
                }}
            >
                <FeatureInfoCard
                    icon={faSquareKanban}
                    title="Tasks"
                    description='Mentioned tasks appear inline, and are actionable without even needing to leave your chat'
                />
                <FeatureInfoCard
                    icon={VideoMeetingIcon}
                    title="Hiyllo Meet"
                    description='Start a 1 on 1 Hiyllo Meet video/voice call right from a chat'
                />
            </div> */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 20,
                    paddingTop: 30
                }}
            >
                <Typography.Header>Packed with all the features you could ever need.</Typography.Header>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 10,
                        maxWidth: '70vw',
                        paddingBottom: 50
                    }}
                >
                    <FeaturePill
                        icon={faFont}
                        label='Advanced Formatting'
                    />
                    <FeaturePill
                        icon={NymblIcon}
                        label='Text Generation Built-In'
                    />
                    <FeaturePill
                        icon={faMailForward}
                        label='Company-Level Forwarding Rules'
                    />
                </div>
            </div>
            <Footer/>
        </Container>
    );
});
