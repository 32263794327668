import React from 'react';
import { InfoHeader } from '../header';
import { styled } from '@hiyllo/ux/styled';
import { Typography } from '@hiyllo/ux/typography';
import { GradientText } from '../../landing';
import { faAt, faFont, faHashtag, faReply, faSearch, faSmileWink, faSquareKanban, faThumbTack, faUser, faUsers } from '@fortawesome/pro-light-svg-icons';
import { ChatThreadIcon, NymblIcon, VideoMeetingIcon } from '@hiyllo/icons/main';
import { FeatureInfoCard, FeaturePill } from '../components';
import { Footer } from '../footer';
import { FakeChatWindow } from '../../fake-chat-window';
import { motion } from 'framer-motion';

const Container = styled('div', ({ $theme }) => ({
    color: $theme.foreground
}));

export const ChatPage = React.memo(function ChatPage(): JSX.Element {
    return (
        <Container>
            <InfoHeader/>
            <div style={{ height: 90 }}/>
            <div
                style={{
                    height: '75vh',
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative'
                }}
            >
                <motion.div
                    initial={{
                        opacity: 0,
                        transform: 'rotateY(40deg) rotateZ(10deg)'
                    }}
                    animate={{
                        opacity: 1,
                        transform: 'rotateY(0deg) rotateZ(0deg)'
                    }}
                    transition={{ duration: 1, delay: 0 }}
                    style={{ position: 'absolute', top: '2.5%', left: '2.5%', width: '30vw', height: 275 }}
                >
                    <img src="/ss/chat-with-last-read.png" style={{
                        borderRadius: 5,
                        width: '100%', height: 'auto',
                        boxShadow: '0px 0px 20px 20px rgba(0,0,0,0.25)'
                     }}/>
                </motion.div>
                <motion.div
                    initial={{
                        opacity: 0,
                        transform: 'rotateY(40deg) rotateZ(10deg)'
                    }}
                    animate={{
                        opacity: 1,
                        transform: 'rotateY(0deg) rotateZ(0deg)'
                    }}
                    transition={{ duration: 1, delay: 0.4 }}
                    style={{ position: 'absolute', bottom: '15%', right: '5%' }}
                >
                    <FakeChatWindow/>
                </motion.div>
                <div style={{ overflow: 'hidden' }}>
                    <video
                        src="/media/chat-integration-demo-hr.mov"
                        autoPlay
                        loop
                        muted
                        controls={false}
                        style={{
                            margin: -2,
                            height: '50vh',
                            width: 'auto',
                            objectFit: 'contain',
                            borderRadius: 5
                        }}
                    />
                </div>
                <div style={{ height: 40 }}/>
                <Typography.Header style={{ textAlign: 'center' }}>Chat on Hiyllo Work<br/>keeps your team <GradientText>moving</GradientText>.</Typography.Header>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    padding: 40,
                    gap: 20
                }}
            >
                <FeatureInfoCard
                    icon={faSmileWink}
                    title="Fully customizable"
                    description='Chat supports unlimited custom emojis, markdown based formatting, and more; letting you and your team express yourselves to the fullest'
                />
                <FeatureInfoCard
                    icon={faUsers}
                    title="Easy access"
                    description='In addition to individual users, you can add entire teams to a channel, allowing new team members to dynamically get access to everything they need'
                />
                <FeatureInfoCard
                    icon={NymblIcon}
                    title="AI-built in"
                    description='Nymbl can summarize your unread messages and entire threads, as well as automatically generate tasks from action items'
                />
            </div>
            <div>
                <Typography.Header style={{ textAlign: 'center' }}>One platform, one ecosystem</Typography.Header>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    padding: 40,
                    gap: 20
                }}
            >
                <FeatureInfoCard
                    icon={faSquareKanban}
                    title="Tasks"
                    description='Mentioned tasks appear inline, and are actionable without even needing to leave your chat'
                />
                <FeatureInfoCard
                    icon={VideoMeetingIcon}
                    title="Hiyllo Meet"
                    description='Start a 1 on 1 Hiyllo Meet video/voice call right from a chat'
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 20,
                    paddingTop: 30
                }}
            >
                <Typography.Header>Packed with all the features you could ever need.</Typography.Header>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 10,
                        maxWidth: '70vw',
                        paddingBottom: 50
                    }}
                >
                    <FeaturePill
                        icon={faUser}
                        label='1 on 1 Chats'
                    />
                    <FeaturePill
                        icon={faUsers}
                        label='Group Chats'
                    />
                    <FeaturePill
                        icon={faHashtag}
                        label='Private & Public Chats'
                    />
                    <FeaturePill
                        icon={faFont}
                        label='Markdown Formatting'
                    />
                    <FeaturePill
                        icon={faSearch}
                        label='AI-Backed Semantic Search'
                    />
                    <FeaturePill
                        icon={ChatThreadIcon}
                        label='Message Threads'
                    />
                    <FeaturePill
                        icon={faReply}
                        label='Inline Replies'
                    />
                    <FeaturePill
                        icon={faThumbTack}
                        label='Message Pinning'
                    />
                    <FeaturePill
                        icon={faAt}
                        label='Mentions'
                    />
                </div>
            </div>
            <Footer/>
        </Container>
    );
});
