import React from 'react';
import { InfoHeader } from '../header';
import { styled } from '@hiyllo/ux/styled';
import { Typography } from '@hiyllo/ux/typography';
import { GradientText } from '../../landing';
import { faAt, faBoothCurtain, faBroadcastTower, faBrowser, faCalendar, faComment, faCrateEmpty, faCrystalBall, faDiagramNested, faFont, faHashtag, faListCheck, faPaperclip, faReply, faRunning, faSearch, faSmileWink, faSortSizeDown, faSparkles, faSquareKanban, faThumbTack, faUpDown, faUser, faUsers } from '@fortawesome/pro-light-svg-icons';
import { ChatThreadIcon, NymblIcon, ScreenshareIcon, VideoMeetingIcon } from '@hiyllo/icons/main';
import { FeatureInfoCard, FeaturePill } from '../components';
import { Footer } from '../footer';
import { FakeChatWindow } from '../../fake-chat-window';
import { motion } from 'framer-motion';

const Container = styled('div', ({ $theme }) => ({
    color: $theme.foreground
}));

export const MeetPage = React.memo(function MeetPage(): JSX.Element {
    return (
        <Container>
            <InfoHeader/>
            <div style={{ height: 90 }}/>
            <div
                style={{
                    height: '75vh',
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative'
                }}
            >
                <div style={{ overflow: 'hidden', borderRadius: 5, boxShadow: '0px 0px 20px 20px rgba(0,0,0,0.25)' }}>
                    <img
                        src="/media/meet.png"
                        style={{
                            height: '50vh',
                            width: 'auto',
                            objectFit: 'contain',
                            borderRadius: 5
                        }}
                    />
                </div>
                <div style={{ height: 40 }}/>
                <Typography.Header style={{ textAlign: 'center' }}>
                    Video Meetings<br/>made effortlessly <GradientText>simple</GradientText>.
                </Typography.Header>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    padding: 40,
                    gap: 20
                }}
            >
                <FeatureInfoCard
                    icon={faCrystalBall}
                    title="Crystal-clear"
                    description={'We prioritize quality over everything else. With every user license purchased, your organization gets dedicated server capacity to ensure the best quality video calls'}
                />
                <FeatureInfoCard
                    icon={faBoothCurtain}
                    title="Streamlined interface"
                    description='We skipped the fake moustaches and other gimmicks, and focused on what matters most for day-to-day work'
                />
                <FeatureInfoCard
                    icon={faBrowser}
                    title="No downloads needed"
                    description="We don't feel bad if you don't wanna download our software, Hiyllo Meet has first-class support for all modern browsers."
                />
            </div>
            {/* <div>
                <Typography.Header style={{ textAlign: 'center' }}>One platform, one ecosystem</Typography.Header>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    padding: 40,
                    gap: 20
                }}
            >
                <FeatureInfoCard
                    icon={faCalendar}
                    title="Calendar"
                    description='Send a link to a task in chat and it appear inline, and is actionable without even needing to leave your chat'
                />
            </div> */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 20,
                    paddingTop: 30
                }}
            >
                <Typography.Header>Packed with all the features you could ever need.</Typography.Header>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 10,
                        maxWidth: '70vw',
                        paddingBottom: 50
                    }}
                >
                    <FeaturePill
                        icon={faBroadcastTower}
                        label='Webinar Support'
                    />
                    <FeaturePill
                        icon={faSparkles}
                        label='Background Blurring'
                    />
                    <FeaturePill
                        icon={ScreenshareIcon}
                        label='Screen Share'
                    />
                </div>
            </div>
            <Footer/>
        </Container>
    );
});
