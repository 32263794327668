import React from 'react';
import { InfoHeader } from '../header';
import { styled } from '@hiyllo/ux/styled';
import { Typography } from '@hiyllo/ux/typography';
import { GradientText } from '../../landing';
import { faAt, faCalendarPen, faCalendarUsers, faClock, faFont, faHashtag, faKeyboard, faLink, faReply, faSearch, faSmileWink, faSquareKanban, faThumbTack, faUser, faUsers } from '@fortawesome/pro-light-svg-icons';
import { ChatThreadIcon, NymblIcon, VideoMeetingIcon } from '@hiyllo/icons/main';
import { FeatureInfoCard, FeaturePill } from '../components';
import { Footer } from '../footer';
import { FakeChatWindow } from '../../fake-chat-window';
import { motion } from 'framer-motion';

const Container = styled('div', ({ $theme }) => ({
    color: $theme.foreground
}));

export const StuffPage = React.memo(function StuffPage(): JSX.Element {
    return (
        <Container>
            <InfoHeader/>
            <div style={{ height: 90 }}/>
            <div
                style={{
                    height: '75vh',
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative'
                }}
            >
                <motion.div
                    initial={{
                        opacity: 0,
                        transform: 'rotateY(40deg) rotateZ(10deg)'
                    }}
                    animate={{
                        opacity: 1,
                        transform: 'rotateY(0deg) rotateZ(0deg)'
                    }}
                    transition={{ duration: 1, delay: 0 }}
                    style={{ position: 'absolute', top: 0, left: 30 }}
                >
                    <div style={{ overflow: 'hidden', borderRadius: 5, boxShadow: '0px 0px 20px 20px rgba(0,0,0,0.25)' }}>
                        <video
                            src="/media/stuff-docs.mov"
                            autoPlay
                            loop
                            muted
                            controls={false}
                            style={{
                                margin: -2,
                                height: '50vh',
                                width: 'auto',
                                objectFit: 'contain',
                                borderRadius: 5
                            }}
                        />
                    </div>
                </motion.div>
                <motion.div
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 0.8
                    }}
                    transition={{ duration: 1, delay: 2 }}
                    style={{ position: 'absolute', top: '2.5%', right: '2.5%' }}
                >
                    Demo videos below are unedited<br/>It&apos;s really that quick
                </motion.div>
                <motion.div
                    initial={{
                        opacity: 0,
                        transform: 'rotateY(40deg) rotateZ(10deg)'
                    }}
                    animate={{
                        opacity: 1,
                        transform: 'rotateY(0deg) rotateZ(0deg)'
                    }}
                    transition={{ duration: 1, delay: 0 }}
                    style={{ position: 'absolute', bottom: '2.5%', right: '2.5%' }}
                >
                    <div style={{ overflow: 'hidden', borderRadius: 5, boxShadow: '0px 0px 20px 20px rgba(0,0,0,0.25)' }}>
                        <video
                            src="/media/stuff-upload-and-chat.mov"
                            autoPlay
                            loop
                            muted
                            controls={false}
                            style={{
                                margin: -2,
                                marginBottom: -4,
                                height: '50vh',
                                width: 'auto',
                                objectFit: 'contain',
                                borderRadius: 5,
                                boxShadow: '0px 0px 20px 20px rgba(0,0,0,0.25)'
                            }}
                        />
                    </div>
                </motion.div>
                <div style={{ height: 40 }}/>
                <div
                    style={{ position: 'absolute', bottom: '2.5%', left: '2.5%' }}
                >
                    <Typography.Header style={{ textAlign: 'left' }}><GradientText>Stuff<sup>1</sup></GradientText> is better<br/>on Hiyllo Work</Typography.Header>
                    <Typography.Paragraph><sup>1</sup> Our all-encompassing term for File Storage, Docs, etc</Typography.Paragraph>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    padding: 40,
                    gap: 20
                }}
            >
                <FeatureInfoCard
                    icon={faKeyboard}
                    title="Built for powerusers"
                    description="With the command palette, extensive keyboard shortcuts, and more; Stuff on Hiyllo Work is a poweruser's dream"
                />
                <FeatureInfoCard
                    icon={faUsers}
                    title="Easy access"
                    description={'Share entire folders or single items with individual coworkers or entire teams'}
                />
                <FeatureInfoCard
                    icon={NymblIcon}
                    title="AI-built in"
                    description='We leverage industry standard LLMs to power text-generation in docs, and more'
                />
            </div>
            {/* <div>
                <Typography.Header style={{ textAlign: 'center' }}>One platform, one ecosystem</Typography.Header>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    padding: 40,
                    gap: 20
                }}
            >
                <FeatureInfoCard
                    icon={VideoMeetingIcon}
                    title="Hiyllo Meet"
                    description='Every calendar event is automatically a Hiyllo Meet. Access for internal guests is handled automatically, no configuration needed. External guests get emailed a secure link to join.'
                />
            </div> */}
            {/* <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 20,
                    paddingTop: 30
                }}
            >
                <Typography.Header>Packed with all the features you could ever need.</Typography.Header>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 10,
                        maxWidth: '70vw',
                        paddingBottom: 50
                    }}
                >
                    <FeaturePill
                        icon={faLink}
                        label='Scheduling Links (Beta)'
                    />
                    <FeaturePill
                        icon={faCalendarPen}
                        label='Scheduling Assistant'
                    />
                    <FeaturePill
                        icon={faCalendarUsers}
                        label='User-Configurable Working Hours'
                    />
                </div>
            </div> */}
            <Footer/>
        </Container>
    );
});
