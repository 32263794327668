import React from 'react';
import { InfoHeader } from '../header';
import { styled } from '@hiyllo/ux/styled';
import { Typography } from '@hiyllo/ux/typography';
import { GradientText } from '../../landing';
import { faAt, faCalendarPen, faCalendarUsers, faClock, faCode, faFile, faFileDoc, faFont, faHashtag, faLink, faReply, faSearch, faSmileWink, faSquareKanban, faThumbTack, faTransporter2, faUser, faUsers } from '@fortawesome/pro-light-svg-icons';
import { ChatThreadIcon, NymblIcon, VideoMeetingIcon } from '@hiyllo/icons/main';
import { FeatureInfoCard, FeaturePill } from '../components';
import { Footer } from '../footer';
import { FakeChatWindow } from '../../fake-chat-window';
import { motion } from 'framer-motion';
import { HiylloIcon } from '@hiyllo/icons/main/src/hiyllo';

const Container = styled('div', ({ $theme }) => ({
    color: $theme.foreground
}));

export const QAEngineerTakehomePage = React.memo(function QAEngineerTakehomePage(): JSX.Element {
    return (
        <Container>
            <div style={{ padding: 40 }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 17.5
                    }}
                >
                    <HiylloIcon width={30} color='white'/>
                    <Typography.SubHeader>QA Engineer, Intern</Typography.SubHeader>
                </div>
                <div style={{ fontSize: 200, fontWeight: 'bold' }}>
                    Welcome to the last stage!
                </div>
                <div style={{ fontSize: 20, paddingTop: 40 }}>
                    Please complete <b>both</b> of the following tasks. You can use any AI assistive tools (ChatGPT, Claude, etc) you wish. Time limits are self-enforced, but keep in mind they reflect the time expectations you&apos;d receive as an employee.
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    padding: 40,
                    gap: 20
                }}
            >
                <FeatureInfoCard
                    icon={faFile}
                    title="Document a test case for Netflix"
                    description="Write a detailed test case that describes a test where a user wants to watch Squid Game on the Netflix website. Refer to the actual Netflix website and do not make assumptions. If you do not have access to Netflix, you may subsitute a video watching platform of your choice (but keep it PG). Time limit: 30 mins"
                />
                <FeatureInfoCard
                    icon={faCode}
                    title="Write an automated test case for Twitter"
                    description="Write an automated playwright test that, given a set of input credentials, logs into Twitter and tweets 'Hello, World!'. Assume that no MFA prompt or other security prompt appears. Test case must be runnable on any OS. Time limit: 60 mins"
                />
            </div>
            <div style={{ padding: 20, textAlign: 'center' }}>
                <b>To submit: </b> Please include your document test case in your reply email, either in the email body or as a PDF attachment. Please upload your automated test case to a GitHub repository or gist and provide the link.
            </div>
            <div style={{ padding: 20, textAlign: 'center', fontSize: 10 }}>
                All trademarks belong to their respective owners.
            </div>
            <Footer/>
        </Container>
    );
});
