import React from 'react';
import { EmptySplash } from '@hiyllo/ux/empty-splash';
import { faEmptySet, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import * as GetBillingDetailsBP from '../../../blueprints/billing/get-billing-details';
import * as UpdatePaymentMethodBP from '../../../blueprints/billing/update-payment-method';

import { seamlessClient } from '../../../seamless';
import { GrowLoading } from '../../ux/loading-spinner';
import { CircleButton } from '@hiyllo/ux/circle-button';
import { Card } from '@hiyllo/ux/surface';
import { useShowAlert } from '@hiyllo/ux/dialogs';
import { Button } from '@hiyllo/ux/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@hiyllo/ux/typography';
import { Table, TableRow } from '../table';
import { ProductBadge } from '../products/badge';

export const BillingOverview = React.memo(function BillingOverview(): JSX.Element {
    const elements = useElements();
    const stripe = useStripe();
    const showAlert = useShowAlert();

    const paymentMethodsQuery = seamlessClient.useQuery<GetBillingDetailsBP.Plug>(GetBillingDetailsBP, null);
    const updatePaymentMethodMutation = seamlessClient.useMutation<UpdatePaymentMethodBP.Plug>(UpdatePaymentMethodBP, { querySideEffects: [paymentMethodsQuery] });

    const [updatePMSuccess, setUpdatePMSuccess] = React.useState(false);
    const [updatePMLoading, setUpdatePMLoading] = React.useState(false);

    const onSavePaymentDetails = React.useCallback(async () => {
        if (elements == null || stripe == null) {
          return console.warn({
            elements,
            stripe
          });
        }

        setUpdatePMLoading(true);

        const { error: submitError } = await elements.submit();
        if (submitError != null) {
            setUpdatePMLoading(false);
            return await showAlert({
                title: 'Oops',
                message: submitError.message ?? 'There was an issue with your payment details'
            });
        }

        const { error: createError, paymentMethod } = await stripe.createPaymentMethod({ elements });

        if (createError != null) {
            setUpdatePMLoading(false);
            return await showAlert({
                title: 'Oops',
                message: createError.message ?? 'There was an issue with your payment details'
            });
        }

        await updatePaymentMethodMutation.call({
          stripePaymentMethodId: paymentMethod.id
        });

        setUpdatePMSuccess(true);
        setUpdatePMLoading(false);
      }, [elements, showAlert, stripe, updatePaymentMethodMutation]);

    if (paymentMethodsQuery.isLoading) {
        return <GrowLoading/>;
    }

    if (paymentMethodsQuery.isError) {
        return (
            <EmptySplash
                icon={faExclamationTriangle}
                label='Error loading payment methods'
                hint={paymentMethodsQuery.error.message}
            />
        );
    }

    const { paymentMethods, subscriptions, recentInvoices } = paymentMethodsQuery.data;

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
            <div>
                {paymentMethods.length > 0 ?
                    <Card color="background2">
                        <Typography.Header>Your current payment method</Typography.Header>
                        <div style={{ height: 10 }}/>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {paymentMethods.map(paymentMethod => (
                                <Card color="background3" key={paymentMethod.last4}>
                                    <div style={{ fontSize: 20 }}>
                                        {paymentMethod.expMonth} / {paymentMethod.expYear}
                                        <div style={{ letterSpacing: 2.5 }}>
                                            •••• •••• •••• <span style={{ letterSpacing: 0 }}>{paymentMethod.last4}</span>
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <b>
                                                {paymentMethod.brand.toUpperCase()}
                                            </b>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </Card>
                : null}
                {paymentMethods.length > 0 ? <div style={{ height: 15 }}/> : null}
                <Card color="background2">
                    <div style={{ fontSize: 24, fontFamily: 'hiyllo' }}>{paymentMethods.length > 0 ? 'Update your organization payment method' : 'Setup a payment method for your organization'}</div>

                    <div style={{ height: 15 }}/>

                    <AddressElement options={{ mode: 'billing' }}/>

                    <div style={{ height: 15 }}/>

                    <PaymentElement />

                    <div style={{ height: 15 }}/>

                    <div style={{ display: 'inline-block' }}>
                        <Button
                            onClick={onSavePaymentDetails}
                            isLoading={updatePMLoading}
                            success={updatePMSuccess}
                            label="Save Payment Method"
                        />
                    </div>
                </Card>
            </div>
            <div style={{ flexGrow: 1 }}>
                <Card color="background2">
                    <Typography.Header>Your Subscriptions</Typography.Header>
                    <Typography.Paragraph>Subscriptions always renew at the end of every month</Typography.Paragraph>

                    <div style={{ height: 10 }}/>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        {subscriptions.length === 0 ?
                            <EmptySplash
                                icon={faEmptySet}
                                label='No subscriptions yet'
                                hint="When you subscribe to a Hiyllo business product, it will appear here"
                            />
                        : subscriptions.map(subscription => (
                            <Card color="background3" key={subscription.subscription.uuid}>
                                <Typography.HeaderRow>
                                    <Typography.SubHeader>
                                        {subscription.product.name}
                                    </Typography.SubHeader>
                                    <ProductBadge product={subscription.product} />
                                </Typography.HeaderRow>
                                <div style={{ height: 10 }}/>
                                {subscription.subscription.billingRate.type === 'work' ?
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
                                        <div>
                                            Price per user per month: <b>${subscription.subscription.billingRate.costPerUser / 100}</b>
                                        </div>
                                        <div>
                                            Current billable users: <b>{subscription.product.usage?.billableUsersCurrent ?? 0}</b>
                                        </div>
                                        <div>
                                            Next renewal amount: <b>${(subscription.subscription.nextRenewalAmount ?? ((subscription.subscription.billingRate.costPerUser * (subscription.product.usage?.billableUsersCurrent ?? 1)))) / 100}</b>
                                        </div>
                                    </div>
                                : null}
                            </Card>
                        ))}
                    </div>
                </Card>
            </div>
        </div>
    );
});
