import { faFileUpload,
  faChevronDown,
  faChevronsDown,
  faChevronsUp,
  faChevronUp,
  faEquals,
  faUserSlash,
  faMouse,
  faArrowPointer,
  faUserPlus
} from '@fortawesome/pro-light-svg-icons';
import {
  ContextMenuContainer,
  ContextMenuItem,
  useContextMenu
} from '@hiyllo/ux/context-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NymblIcon } from '@hiyllo/icons/main';
import { ListTasksSlimTaskType } from '@hiyllo/omni-common/src/types/tasks/task-item';
import { TaskPillsRow } from '@hiyllo/omni-tasks/main';
import { styled } from '@hiyllo/ux/styled';
import { useTheme } from '@hiyllo/ux/theme';
import { motion, useMotionValueEvent, useScroll, useTransform } from 'framer-motion';
import React from 'react';
import { EJSONCache } from '@hiyllo/ejson-cache/main';
import { TaskPill } from '@hiyllo/omni-tasks/main/src/ui/common';
import { GradientText } from './landing';

const Container = styled('div', ({ $theme }) => ({
    background: $theme.background1,
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    pointerEvents: 'none'
}));

const InputBar = styled('div', ({ $theme }) => ({
    background: 'rgb(40,40,44)'
}));

const Separator = styled('div', ({ $theme }) => ({
    height: 1,
    background: $theme.midground1
}));

const Input = styled('input', ({ $theme }) => ({
    height: 60,
    width: 'calc(100% - 20px)',
    background: 'transparent',
    border: 'none',
    padding: 0,
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: $theme.foreground,
    fontFamily: 'hiyllo',
    fontSize: 17.5
}));

const Toolbar = styled('div', ({ $theme }) => ({
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    paddingLeft: 10,
    paddingRight: 10
}));

const Avatar = styled('img', ({ $theme }) => ({
    width: 40,
    height: 40,
    borderRadius: 10,
    objectFit: 'cover'
}));

const MessageName = styled('div', ({ $theme }) => ({
    color: $theme.foreground,
    fontFamily: 'hiyllo',
    fontSize: 15,
    fontWeight: 'bold'
}));

const MessageText = styled('div', ({ $theme }) => ({
    color: $theme.foreground,
    fontFamily: 'hiyllo',
    fontSize: 15
    // fontWeight: 'bold'
}));

const Message = React.memo(function Message(props: {
    userImage: string;
    name: string;
    text: string;
}): JSX.Element {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <Avatar src={props.userImage}/>
            <div>
                <MessageName>{props.name}</MessageName>
                <MessageText>{props.text}</MessageText>
            </div>
        </div>
    );
});

const Dock = styled('div', ({ $theme }) => ({
    background: $theme.background1,
    width: 80,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center'
}));

const Sidebar = styled('div', ({ $theme }) => ({
  background: $theme.background2,
  width: 280,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  alignItems: 'center',
  justifyContent: 'flex-start'
}));

const PriorityColorMap = (): Record<number, string> => ({
  0: '#d32f2f', // Red
  1: '#f57c00', // Orange
  2: '#1976d2', // Blue
  3: '#00796b', // Aqua
  4: '#388e3c' // Green
});

export const TinyPillContainer = styled<
  'div',
  { overdue?: boolean; noLeftPadding?: boolean }
>('div', ({ overdue, noLeftPadding }) => ({
  background: overdue ? '#d3302f' : 'rgba(255, 255, 255, 0.2)',
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
  alignItems: 'center',
  borderRadius: 20,
  paddingLeft: noLeftPadding === true ? 0 : 10,
  fontSize: 12,
  overflow: 'hidden'
}));

export const PriorityIconsMap = {
  0: faChevronsUp,
  1: faChevronUp,
  2: faEquals,
  3: faChevronDown,
  4: faChevronsDown
};

export const PillContent = styled<'div', { overdue?: boolean }>(
  'div',
  ({ overdue }) => ({
    backgroundColor: overdue ? '#f44336' : '#5f5e62',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 20,
    gap: 5,
    cursor: 'pointer',
    height: 26
  })
);

export const CondensedDetailsPill = React.memo(
  function CondensedDetailsPill(props: {
    task: Omit<ListTasksSlimTaskType, 'assignee'>;
  }): JSX.Element {
    return (
      <TinyPillContainer style={{ paddingLeft: 0, borderRadius: 5 }}>
        <div
          style={{
            height: 25,
            backgroundColor: PriorityColorMap()[props.task.priority],
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: 5,
            paddingLeft: 5,
            fontSize: 12.5
          }}
        >
          <FontAwesomeIcon
            icon={PriorityIconsMap[props.task.priority]}
            fixedWidth
          />
        </div>
        <div style={{ paddingRight: 5 }}>{props.task.shortId}</div>
      </TinyPillContainer>
    );
  }
);

const IS_MOBILE = window.innerHeight > window.innerWidth;

const cache = new EJSONCache({
  getItem: async () => {
    return null;
  },
  setItem: async () => {
    //
  }
});

export const ScrollApp = React.memo(function ScrollApp(): JSX.Element {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    container: { current: document.getElementById('root') }
  });

  function pixelsToPercentage (pixels: number): number {
    return pixels / (window.innerHeight * 3);
  }

  const containerWidth = useTransform(scrollYProgress, [0, pixelsToPercentage(500)], [IS_MOBILE ? IS_MOBILE ? window.innerWidth - 75 : window.innerWidth - 300 : 300, IS_MOBILE ? window.innerWidth - 75 : window.innerWidth - 300]);
  const containerHeight = useTransform(scrollYProgress, [0, pixelsToPercentage(500)], [IS_MOBILE ? window.innerHeight - 250 : 400, window.innerHeight - 250]);
  const marginTop = useTransform(scrollYProgress, [0, 1], [0, (window.innerHeight * 4) - window.innerHeight]);
  const dockWidth = useTransform(scrollYProgress, [0, pixelsToPercentage(500), pixelsToPercentage(700), pixelsToPercentage(700)], [0, 0, IS_MOBILE ? 0 : 80, IS_MOBILE ? 0 : 80]);
  const sidebarWidth = useTransform(scrollYProgress, [0, pixelsToPercentage(500), pixelsToPercentage(700), pixelsToPercentage(700)], [0, 0, IS_MOBILE ? 0 : 280, IS_MOBILE ? 0 : 280]);
  const captionScroll = useTransform(scrollYProgress, [0, 1], [0, -(window.innerWidth * 3)]);
  const taskHeight = useTransform(scrollYProgress, [0, pixelsToPercentage(1000), pixelsToPercentage(1200), pixelsToPercentage(1200)], [0, 0, 150, 150]);
  const cursorOpacity = useTransform(scrollYProgress, [0, pixelsToPercentage(1300), pixelsToPercentage(1400), pixelsToPercentage(1400)], [0, 0, 1, 1]);
  const cursorX = useTransform(scrollYProgress, [0, pixelsToPercentage(1300), pixelsToPercentage(1500), pixelsToPercentage(1850), pixelsToPercentage(1950), pixelsToPercentage(1950)], [-50, -50, 0, 0, 20, 20]);
  const cursorY = useTransform(scrollYProgress, [0, pixelsToPercentage(1300), pixelsToPercentage(1500), pixelsToPercentage(1850), pixelsToPercentage(1950), pixelsToPercentage(1950)], [-50, -50, 0, 0, -10, -10]);
  const cxmenuOpacity = useTransform(scrollYProgress, [0, pixelsToPercentage(1600), pixelsToPercentage(1650), pixelsToPercentage(2000), pixelsToPercentage(2100)], [0, 0, 1, 1, 0]);
  const selfAssigned = useTransform(scrollYProgress, [0, pixelsToPercentage(2000), pixelsToPercentage(2000), pixelsToPercentage(3000)], [0, 0, 1, 1]);
  const typeProgress = useTransform(scrollYProgress, [0, pixelsToPercentage(2200), pixelsToPercentage(2300), pixelsToPercentage(4000)], [0, 0, 1, 1]);
  const [showAssignee, setShowAssignee] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  useMotionValueEvent(selfAssigned, 'change', v => setShowAssignee(v === 1));
  useMotionValueEvent(typeProgress, 'change', v => {
    const message = 'You got it.';

    setInputValue(message.slice(0, Math.floor(message.length * v)));
  });
  const $theme = useTheme();
  const [fixed, setFixed] = React.useState(false);
  const [bottom, setBottom] = React.useState(false);
  useMotionValueEvent(scrollYProgress, 'change', v => {
    if (v !== 0 && v !== 1) {
      setFixed(true);
    } else {
      if (v === 1) {
        setBottom(true);
      } else {
        setBottom(false);
      }
      setFixed(false);
    }
  });

  return (
    <div style={{ pointerEvents: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '400vh', position: 'relative', overflow: 'hidden' }} ref={ref}>
      <motion.div style={{ [bottom ? 'bottom' : 'top']: bottom ? 'env(safe-area-inset-bottom)' : 0, position: fixed ? 'fixed' : 'absolute', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh' }}>
        <motion.div style={{ borderRadius: 10, overflow: 'hidden', width: containerWidth, height: containerHeight, background: 'green', display: 'flex', flexDirection: 'row', boxShadow: 'rgba(0, 0, 0, 1) 0px 0px 20px 20px' }}>
          <motion.div style={{ width: dockWidth, height: '100%' }}>
            <Dock/>
          </motion.div>
          <motion.div style={{ width: sidebarWidth, height: '100%' }}>
            <Sidebar>
              <div style={{ paddingLeft: 15, gap: 10, paddingTop: 20, paddingRight: 15, width: 'calc(100% - 30px)', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                <img src="/jimmy-fermin-bqe0J0b26RQ-unsplash.jpg" style={{ height: 20, width: 20, borderRadius: 5 }}/>
                Jenny Ermin
              </div>
              <div style={{ paddingLeft: 15, gap: 10, paddingRight: 15, width: 'calc(100% - 30px)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ height: 20, width: 20, borderRadius: 5, backgroundColor: '#333' }}/>
                <div style={{ height: 17.5, width: 200, borderRadius: 5, backgroundColor: '#333' }}/>
              </div>
              <div style={{ paddingLeft: 15, gap: 10, paddingRight: 15, width: 'calc(100% - 30px)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ height: 20, width: 20, borderRadius: 5, backgroundColor: '#333' }}/>
                <div style={{ height: 17.5, width: 200, borderRadius: 5, backgroundColor: '#333' }}/>
              </div>
              <div style={{ paddingLeft: 15, gap: 10, paddingRight: 15, width: 'calc(100% - 30px)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ height: 20, width: 20, borderRadius: 5, backgroundColor: '#333' }}/>
                <div style={{ height: 17.5, width: 200, borderRadius: 5, backgroundColor: '#333' }}/>
              </div>
            </Sidebar>
          </motion.div>
          <Container>
            <div style={{ flexGrow: 1, height: 5 }}/>
            <div style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
                <Message
                    userImage="/jimmy-fermin-bqe0J0b26RQ-unsplash.jpg"
                    name='Jenny Ermin'
                    text={'Hey, what are you working on?'}
                />
                <Message
                    userImage="/alexander-hipp-iEEBWgY_6lA-unsplash.jpg"
                    name="Fermi P'dox"
                    text={'Gonna start with the 3D video support, then cloud stuff.'}
                />
                <motion.div style={{ height: taskHeight, overflow: 'hidden', position: 'relative' }}>
                  <Message
                    userImage="/jimmy-fermin-bqe0J0b26RQ-unsplash.jpg"
                    name='Jenny Ermin'
                    text={'Can you take a look at this after?'}
                  />
                  <div style={{ marginLeft: 50, backgroundColor: $theme.background3, padding: 10, borderRadius: 5 }}>
                    <TaskPillsRow>
                      <CondensedDetailsPill
                        // @ts-expect-error ---
                        task={{
                          shortId: '123',
                          priority: 0
                        }}
                      />
                      {showAssignee ?
                      <TaskPill>
                        <img src="/alexander-hipp-iEEBWgY_6lA-unsplash.jpg" style={{ height: 20, width: 20, borderRadius: 5 }}/>
                        Fermi P&apos;dox
                      </TaskPill>
                    :
                      <TaskPill>
                        <FontAwesomeIcon icon={faUserSlash} fixedWidth/>
                        Unassigned
                      </TaskPill>
}
                    </TaskPillsRow>
                    <div style={{ paddingTop: 10 }}>
                      [Hardware] Prevent pressing delete key on keyboard from impacting database
                    </div>
                  </div>
                  <motion.div
                    style={{ position: 'absolute', zIndex: 50, opacity: cursorOpacity, top: 100, left: 70, x: cursorX, y: cursorY }}
                  >
                    <FontAwesomeIcon icon={faArrowPointer}/>
                  </motion.div>
                  <motion.div style={{ position: 'absolute', bottom: 15, left: 70, opacity: cxmenuOpacity }}>
                    <ContextMenuContainer>
                        <ContextMenuItem
                          icon={faUserPlus}
                          label="Assign to Me"
                          onClick={() => {
                            //
                          }}
                        />
                    </ContextMenuContainer>
                  </motion.div>
                </motion.div>
            </div>
            <InputBar>
                <Toolbar>
                    <NymblIcon width={15} color='white'/>
                    <FontAwesomeIcon icon={faFileUpload} fontSize={15} color="white"/>
                </Toolbar>
                <Separator/>
                <Input
                    disabled
                    value={inputValue}
                    style={{ userSelect: 'none' }}
                />
            </InputBar>
          </Container>
        </motion.div>
        <motion.div style={{ translateX: captionScroll, position: 'absolute', bottom: 0, left: 0, height: 100, display: 'flex', flexDirection: 'row', textAlign: 'center', fontSize: IS_MOBILE ? 17.5 : 25 }}>
          <div style={{ width: '100vw', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
            Hiyllo Work changes the interconnectivity game...
          </div>
          <div style={{ width: '100vw', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
            Tasks, chats, and everything else work together effortlessly. No juggling integrations or worrying about security,&nbsp;<GradientText>it&apos;s all built in</GradientText>.
          </div>
          <div style={{ width: '100vw', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
            One platform means more focus and less platform juggling.
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
});
